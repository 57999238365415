:root {
    --jqx-grid-row-height: 36px;
    --jqx-grid-column-height: 48px;
    --jqx-grid-show-column-lines: 1;
    --jqx-list-item-height: -1;
    --jqx-grid-filter-menu-items-height: 30px;
    --jqx-dropdown-animation: transform;
    --jqx-datetimeinput-dropdown-height: 280px;
    --jqx-datetimeinput-dropdown-width: 280px;
    --jqx-calendar-header-height: 40px;
    --jqx-calendar-title-height: 49px;
    --jqx-icon-calendar: '\e829'; /* Code of calendar icon */
    --jqx-icon-filter: '\f0b0'; /* Code of filter icon */
    --jqx-icon-menu: '\f0c9'; /* Code of menu icon */
    --jqx-icon-check: '\e908'; /* Code of check icon */
    --jqx-icon-first-page: '\e900'; /* Code of first page icon */
    --jqx-icon-arrow-down: '\e901'; /* Code of down arrow icon */
    --jqx-icon-arrow-left: '\e902'; /* Code of left arrow icon */
    --jqx-icon-arrow-right: '\e903'; /* Code of right arrow icon */
    --jqx-icon-arrow-up: '\e904'; /* Code of up arrow icon */
    --jqx-icon-arrow-down-filled: '\e812'; /* Code of filled down arrow icon */
    --jqx-icon-arrow-left-filled: '\e816'; /* Code of filled left arrow icon */
    --jqx-icon-arrow-right-filled: '\e81e'; /* Code of filled right arrow icon */
    --jqx-icon-arrow-up-filled: '\e815'; /* Code of filled up arrow icon */
    --jqx-icon-visibility: '\e90d'; /* Code of visibility icon */
    --jqx-icon-visibility-off: '\e90e'; /* Code of visibility off icon */
    --jqx-icon-last-page: '\e905'; /* Code of last page icon */
    --jqx-icon-close: '\e80d'; /* Code of close icon */
    --jqx-icon-search: '\e828'; /* Code of search icon */
    --jqx-border-radius: 4px;
    --jqx-font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    --jqx-font-size: 14px;
    --jqx-action-button-size: 25px;
}

/*Rounded Corners*/
/*top-left rounded Corners*/
.jqx-rc-tl {
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
}
/*top-right rounded Corners*/
.jqx-rc-tr {
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
}
/*bottom-left rounded Corners*/
.jqx-rc-bl {
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
/*bottom-right rounded Corners*/
.jqx-rc-br {
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
/*top rounded Corners*/
.jqx-rc-t {
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
}
/*bottom rounded Corners*/
.jqx-rc-b {
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
/*right rounded Corners*/
.jqx-rc-r {
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
/*left rounded Corners*/
.jqx-rc-l {
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
/*all rounded Corners*/
.jqx-rc-all {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}
/*reset rounded corners*/
.jqx-rc-reset {
    -moz-border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    border-radius: 0px !important;
}

/*Reset Style*/
.jqx-reset {
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
    outline: none;
    box-sizing: content-box;
}

.jqx-clear {
    align: left;
    valign: top;
    left: 0px;
    top: 0px;
    -webkit-appearance: none !important;
    outline: none !important;
    padding: 0px;
    margin: 0px;
}

.jqx-popup {
    box-sizing: content-box;
}

.jqx-position-reset {
    position: static !important;
}

.jqx-border-reset {
    border: none !important;
}

.jqx-overflow-hidden {
    overflow: hidden !important;
}

.jqx-position-relative {
    position: relative !important;
}

.jqx-position-absolute {
    position: absolute !important;
}

.jqx-max-size {
    width: 100% !important;
    height: 100% !important;
}

.jqx-background-reset {
    background: transparent !important;
}
/*Disable browser selection*/
.jqx-disableselect {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.jqx-enableselect {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: all;
}

.jqx-hideborder {
    border: none !important;
}

.jqx-hidescrollbars {
    overflow: hidden !important;
}
/*jqxButton, jqxToggleButton, jqxRepeatButton Style*/
.jqx-button {
    outline: none;
    border: 1px solid transparent;
    padding: 5px;
    text-align: center;
    vertical-align: central;
    margin: 0 0 0 0;
    cursor: default;
}
.jqx-button[aria-disabled="true"] {
    pointer-events:none;
}
button.jqx-button, input[type=button].jqx-button, input[type=submit].jqx-button {
    box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
}

.jqx-buttongroup {
}

.jqx-group-button-normal {
    vertical-align: middle;
    text-align: center;
}
/*applied to the link button's anchor element.*/
.jqx-link {
    text-decoration: none;
    color: #111111;
}

/*shows elements.*/
.jqx-visible {
    display: block;
}
/*hides elements.*/
.jqx-hidden {
    display: none;
}

.jqx-widget, .jqx-widget-content, .jqx-widget-header, .jqx-fill-state-normal {
    line-height: 1.231;
}
/*jqxGauge Style*/
.jqx-gauge-label, .jqx-gauge-caption {
    fill: #333333;
    color: #333333;
    font-size: 11px;
    font-family: Verdana;
}

.jqx-knob {
    text-align: center;
}
/*jqxChart Style*/
.jqx-knob-label,
.jqx-chart-axis-text,
.jqx-chart-label-text,
.jqx-chart-tooltip-text,
.jqx-chart-legend-text {
    fill: #333333;
    color: #333333;
    font-size: 11px;
    font-family: Verdana;
}

.jqx-chart-axis-description {
    fill: #555555;
    color: #555555;
    font-size: 11px;
    font-family: Verdana;
}

.jqx-chart-title-text {
    fill: #111111;
    color: #111111;
    font-size: 14px;
    font-weight: bold;
    font-family: Verdana;
}

.jqx-chart-title-description {
    fill: #111111;
    color: #111111;
    font-size: 12px;
    font-weight: normal;
    font-family: Verdana;
}

/*applied to the Grid's element.*/
.jqx-grid {
    overflow: hidden;
    border-style: solid;
    border-width: 1px;
}

    .jqx-grid .jqx-adaptive-table {
        border-collapse: collapse;
        border: none;
        width: 100%;
    }

        .jqx-grid .jqx-adaptive-table td, .jqx-grid .jqx-adaptive-table th {
            border-bottom: 1px solid #ddd;
            padding: 8px;
            overflow: auto;
        }

        .jqx-grid .jqx-adaptive-table tr:nth-child(even) {
            background-color: #f2f2f2;
        }

        .jqx-grid .jqx-adaptive-table th {
            padding-top: 12px;
            padding-bottom: 12px;
            text-align: left;
            background-color: #4CAF50;
            color: white;
        }

    .jqx-grid .jqx-adaptive-view a {
        padding: 10px;
        text-decoration: none;
        color: orangered;
    }

/*applied to the Pager.*/
.jqx-grid-pager {
    border: none;
    border-top: 1px solid transparent;
}
/*applied to the Top Pager.*/
.jqx-grid-pager-top {
    border: none;
    border-bottom: 1px solid transparent;
}

.jqx-grid-pager-number {
    padding: 2px 5px;
    text-decoration: none;
    margin: 1px;
    border: 1px solid transparent;
    color: inherit !important;
    outline: none;
    font-weight: normal !important;
    line-height: 14px;
}

.jqx-grid-menu-item-touch {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}
/*applied to the Grid's bottom-right area between the horizontal and vertical scrollbars.*/
.jqx-grid-bottomright {
}
/*applied to the Grid's header area.*/
.jqx-grid-header {
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    overflow: hidden;
    border-color: transparent;
    margin: 0px;
    padding: 0px;
}
/*applied to the columns.*/
.jqx-grid-column-header, .jqx-grid-columngroup-header {
    border-style: solid;
    border-width: 0px 1px 0px 0px;
    border-color: transparent;
    white-space: nowrap;
    border-style: solid;
    font-weight: normal;
    overflow: hidden;
    text-align: left;
    padding: 0px;
    margin: 0px;
}

.jqx-grid-column-header-rtl, .jqx-grid-columngroup-header-rtl {
    border-width: 0px 0px 0px 1px;
}

.jqx-grid-columngroup-header {
    border-bottom-width: 1px;
    border-left-width: 1px;
}
/*applied to the column's button.*/
.jqx-grid-column-menubutton {
    border-style: solid;
    border-width: 0px 0px 0px 1px;
    border-color: transparent;
    background-image: url('images/icon-menu-small.png') !important;
    background-repeat: no-repeat;
    background-position: center !important;
    cursor: pointer;
}

.jqx-grid-column-menubutton-rtl {
    border-width: 0px 1px 0px 0px;
}
/*applied to the column's sort button when the sort order is ascending.*/
.jqx-grid-column-sortascbutton {
    border: none;
    background-image: url('images/sortascbutton.png');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}
/*applied to the column's sort button when the sort order is descending.*/
.jqx-grid-column-sortdescbutton {
    border: none;
    background-image: url('images/sortdescbutton.png');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}
/*applied to the column's filter button.*/
.jqx-grid-column-filterbutton {
    border: none;
    background-image: url('images/filter.png');
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 1px;
}
/*applied to the column's resize line.*/
.jqx-grid-column-resizeline {
    border-right: 1px dotted #555;
}
/*applied to the validation popup during cell editing.*/
.jqx-grid-validation {
    background: #df2227 !important;
    color: #fff !important;
    padding: 4px 8px;
}

.jqx-grid-validation-label {
    color: #fff !important;
    background: #df2227 !important;
    border: 1px solid #df2227 !important;
}

.jqx-grid-validation-arrow-up {
    background-image: url('images/red_arrow_up.png');
    background-repeat: no-repeat;
    background-position: center center;
}

.jqx-grid-validation-arrow-down {
    background-image: url('images/red_arrow_down.png');
    background-repeat: no-repeat;
    background-position: center center;
}
/*applied to the column's resize start line.*/
.jqx-grid-column-resizestartline {
    border-right: 1px solid #444;
}
/*applied to the sort ascending menu item in the Grid's Context Menu*/
.jqx-grid-sortasc-icon {
    background-image: url('images/icon-sort-asc.png');
    background-repeat: no-repeat;
    background-position: left center;
    width: 16px;
    height: 16px;
    float: left;
    margin-left: -4px;
    margin-right: 4px;
}
/*applied to the sort ascending menu item in the Grid's Context Menu*/
.jqx-grid-sortdesc-icon {
    background-image: url('images/icon-sort-desc.png');
    background-repeat: no-repeat;
    background-position: left center;
    width: 16px;
    height: 16px;
    float: left;
    margin-left: -4px;
    margin-right: 4px;
}
/*applied to the grid menu's sort remove item/*/
.jqx-grid-sortremove-icon {
    background-image: url('images/icon-sort-remove.png');
    background-repeat: no-repeat;
    background-position: left center;
    width: 16px;
    height: 16px;
    float: left;
    margin-left: -4px;
    margin-right: 4px;
}
/*applied to the grouping column's drag and drop item when the drop operation is allowed.*/
.jqx-grid-drag-icon {
    background-image: url('images/drag.png');
    background-repeat: no-repeat;
    background-position: left center;
    width: 16px;
    height: 16px;
}
/*applied to the grouping column's drag and drop item when the drop operation is not allowed.*/
.jqx-grid-dragcancel-icon {
    background-image: url('images/dragcancel.png');
    background-repeat: no-repeat;
    background-position: left center;
    width: 16px;
    height: 16px;
}
/*applied to the 'group by' menu item.*/
.jqx-grid-groupby-icon {
    background-repeat: no-repeat;
    background-position: left center;
    width: 16px;
    height: 16px;
    float: left;
    margin-left: -4px;
    margin-right: 4px;
}
/*applied to the column anchor tags.*/
.jqx-grid-column-header a:link, .jqx-grid-column-header a:visited {
    display: block;
    margin: 4px;
    height: 18px;
    line-height: 18px !important;
    color: inherit;
    outline: 0 none;
    text-decoration: none;
    cursor: pointer;
}

.jqx-grid-toolbar a:link, .jqx-grid-toolbar a:visited {
    margin: 4px;
    height: 18px;
    line-height: 18px !important;
    color: inherit;
    outline: 0 none;
    text-decoration: none;
    cursor: pointer;
}
/*applied to the groups header area.*/
.jqx-grid-groups-header, .jqx-grid-toolbar, .jqx-scheduler-toolbar {
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: #aaa;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    padding: 0px;
    margin: 0px;
}
/*applied to a grouping row.*/
.jqx-grid-groups-row {
    font-weight: bold;
    white-space: nowrap;
    text-align: left;
    padding: 0px;
    top: 50%;
    margin-top: -8px;
}
/*applied to the row details.*/
.jqx-grid-groups-row-details {
    font-weight: normal;
}
/*applied to a grouping column.*/
.jqx-grid-group-column {
    border: 1px solid transparent;
    white-space: nowrap;
    border-style: solid;
    font-weight: normal;
    overflow: hidden;
    text-align: left;
    padding: 0px;
    margin: 0px;
}
/*applied to the lines between the group columns.*/
.jqx-grid-group-column-line {
    background: #aaa;
}
/*applied to the drop line indicators displayed in the grouping header.*/
.jqx-grid-group-drag-line {
    background: #80be09;
}
/*applied to the anchor tags inside a grouping column.*/
.jqx-grid-group-column a:link {
    display: block;
    margin: 4px;
    height: 18px;
    line-height: 18px !important;
    outline: 0 none;
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}

.jqx-grid-group-column a:visited {
    color: inherit;
    display: block;
    margin: 4px;
    height: 18px;
    line-height: 18px !important;
    outline: 0 none;
    text-decoration: none;
    cursor: pointer;
}
/*applied to a grid cell*/
.jqx-grid-cell {
    border-style: solid;
    border-width: 0px 1px 1px 0px;
    margin-right: 1px;
    margin-bottom: 1px;
    border-color: transparent;
    background: #fff;
    white-space: nowrap;
    font-weight: normal;
    font-size: inherit;
    overflow: hidden;
    position: absolute !important;
    height: 100%;
    outline: none;
}

.jqx-grid-cell-locked {
    color: #aaa !important;
}

.jqx-grid-cell-left-align {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 2px;
    text-align: left;
    margin-right: 2px;
    margin-left: 4px;
}

.jqx-grid-cell-middle-align {
    text-overflow: ellipsis;
    overflow: hidden;
    padding-bottom: 2px;
    text-align: center;
}

.jqx-grid-cell-right-align {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 2px;
    text-align: right;
    margin-right: 4px;
    margin-left: 2px;
}

/*applied to a grid cell that is part of a rows group.*/
.jqx-grid-group-cell {
    overflow: hidden;
    position: absolute !important;
    height: 100%;
    background: #fff;
}
/*applied to the grid cells in the sort column*/
.jqx-grid-cell-sort {
    background-color: #f0f0f0;
}

/*applied to the grid cells in the filter column*/
.jqx-grid-cell-filter {
    background-color: #e6e6e6;
}

.jqx-grid-cell-add-new-row {
    background: #fdfcf3 !important;
    color: #000 !important;
}

/*applied to the grid cells area.*/
.jqx-grid-content {
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    border-color: transparent;
    white-space: nowrap;
    overflow: hidden;
}
/*applied to a grid cell that is part of a rows group.*/
.jqx-grid-group-cell, .jqx-grid-empty-cell {
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    white-space: nowrap;
    font-weight: normal;
}

.jqx-widget .jqx-grid-cleared-cell, .jqx-grid-cleared-cell {
    border: transparent !important;
}

.jqx-grid-cell-wrap {
    white-space: normal !important;
}

.jqx-tree-grid-indent {
    width: 18px;
    display: inline-block;
    height: 8px;
    overflow: hidden;
    vertical-align: top;
}

.jqx-tree-grid-title {
    text-decoration: none;
    vertical-align: bottom;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.jqx-tree-grid-collapse-button, .jqx-tree-grid-expand-button {
    display: inline-block;
    margin-top: 0px;
    margin-right: 0px;
    position: relative;
    width: 18px;
    height: 18px;
    vertical-align: top;
}

.jqx-tree-grid-checkbox {
    float: none !important;
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin-right: 6px !important;
    margin-left: 2px !important;
}

.jqx-tree-grid-icon {
    float: none !important;
    width: 14px;
    height: 14px;
    margin-right: 4px;
}

.jqx-tree-grid-icon-rtl {
    margin-right: 0px;
    margin-left: 4px;
}

.jqx-tree-grid-icon-size {
    width: 14px;
    height: 14px;
    line-height: 16px;
}

.jqx-tree-grid-checkbox-tick {
    width: 100%;
    height: 100%;
}

.jqx-tree-grid-expand-button {
    height: 18px;
    width: 18px;
    vertical-align: top;
}

/*applied to a cell in jqxDataTable*/
.jqx-cell {
    min-height: 23px;
    padding: 6px 4px;
    margin: 0;
    border-collapse: separate;
    border-spacing: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid transparent;
    border-top-width: 0px;
    border-left-width: 0px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    white-space: normal;
    line-height: 1.231;
}

.jqx-grid-cell-nowrap {
    white-space: nowrap;
}

.jqx-cell-editor {
    padding-left: 4px !important;
    padding-right: 3px !important;
}

.jqx-left-align {
    text-align: left;
}

.jqx-center-align {
    text-align: center;
}

.jqx-right-align {
    text-align: right;
}

.jqx-top-align {
    vertical-align: top;
}

.jqx-bottom-align {
    vertical-align: bottom;
}

.jqx-grid-table .jqx-grid-cell {
    position: static !important;
    zoom: 1 !important;
}
/*applied to the Grid's Table element.*/
.jqx-grid-table {
    font-size: 13px;
    table-layout: fixed;
    margin: 0px;
    padding: 0px;
    border-collapse: separate;
    border-spacing: 0px;
    -webkit-overflow-scrolling: touch;
    outline: none;
    zoom: 1 !important;
    empty-cells: show;
    border-right: 1px solid #aaa;
    line-height: 1.231;
    width: auto;
    border: none;
}

.jqx-grid-table-rtl {
    border-left-width: 1px;
    border-right-width: 0px;
}

.jqx-details table {
    border-collapse: collapse;
    table-layout: fixed;
    border-spacing: 0px;
}

/*applied to a grid cell that is part of a details row and grouping is enabled.*/
.jqx-grid-group-details-cell {
}
/*applied to a grid cell that is part of a details row.*/
.jqx-grid-details-cell {
    border-width: 0px 1px 0px 0px;
}
/*applied to a grid cell. Represents the alternating cell background.*/
.jqx-grid-cell-alt {
    background: #f9f9f9;
}
/*applied to a sorted and alternating grid cell*/
.jqx-grid-cell-sort-alt {
    background-color: #e5e5e5;
}
/*applied to a sorted and alternating grid cell*/
.jqx-grid-cell-filter-alt {
    background-color: #f0f0f0;
}
/*applied to a pinned grid cell.*/
.jqx-grid-cell-pinned {
    background-color: #e5e5e5;
}
/*applied to a pinned grid cell. Represents the alternating cell background.*/
.jqx-grid-cell-pinned-alt {
    background-color: #e5e5e5;
}
/*applied to a selected grid cell.*/
.jqx-grid-cell-selected {
    border-left: 0px solid transparent;
}

    .jqx-grid-cell-selected.jqx-grid-cell-edit {
        border-color: #DDDDDD !important;
        background-color: #fff !important;
        color: #333 !important;
    }

.jqx-fill-state-focus.jqx-grid-cell-edit,
.jqx-grid-cell-edit .jqx-fill-state-focus,
.jqx-grid-cell-edit.jqx-fill-state-focus .jqx-action-button,
.jqx-grid-cell-edit .jqx-numberinput-focus,
.jqx-grid-cell-edit .jqx-combobox-content-focus {
    border-color: #DDDDDD !important;
}

.jqx-cell-rtl {
    border-left-width: 1px;
    border-right-width: 0px;
}

.jqx-grid-cell-rtl {
    border-width: 0px 0px 1px 1px;
}

.jqx-grid-table .jqx-grid-cell {
    border-width: 0px 0px 1px 1px;
}

.jqx-grid-table-one-cell {
    border: none;
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: #aaa;
}

.jqx-grid-table .jqx-grid-cell:first-child {
    border-left-width: 0px;
}

/*applied to the selection area.*/
.jqx-grid-selectionarea {
    border: 1px solid transparent;
    opacity: 0.5;
    filter: alpha(opacity=50);
}
/*applied to a hovered grid cell.*/
.jqx-grid-cell-hover {
    border-color: transparent;
}
/*applied to a grid cell when the grid is empty.*/
.jqx-grid-empty-cell {
    overflow: visible;
    border-bottom: none;
}

/*applied to the Grid when its loading the data.*/
.jqx-grid-load {
    padding-right: 0px;
    background-image: url(./images/loader.gif);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    z-index: 9999;
    display: block;
}
/*applied to a group's collapse button.*/
.jqx-grid-group-collapse {
    padding-right: 0px;
    background-image: url(./images/icon-right.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    cursor: pointer;
}

.jqx-grid-group-collapse-rtl {
    padding-right: 0px;
    background-image: url(./images/icon-left.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    cursor: pointer;
}
/*applied to a group's expand button.*/
.jqx-grid-group-expand, .jqx-grid-group-expand-rtl {
    padding-right: 0px;
    background-image: url(./images/icon-down.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    cursor: pointer;
}
/*jqxProgresBar Style*/
.jqx-progressbar {
    position: relative;
    overflow: hidden;
    outline: none;
    border: 1px solid transparent;
    height: 2em;
}
/*applied to the progressbar's value element*/
.jqx-progressbar-value {
    outline: none;
    border: 0px solid transparent;
    height: 100%;
}
/*applied to the progress bar's vertical value element- when the widget's orientation is 'vertical'*/
.jqx-progressbar-value-vertical {
    outline: none;
    border: 0px solid transparent;
    height: 100%;
    background: transparent;
}
/*applied to the progressbar's text element*/
.jqx-progressbar-text {
    font-size: 10px;
    border: none;
}
/*applied to the progress bar when the widget is in disabled state*/
.jqx-progressbar-disabled {
    outline: none;
    border: 1px solid transparent;
    height: 2em;
}

/*jqxMenu Style*/
.jqx-menu {
    border: 1px solid transparent;
    float: none;
    margin: 0px;
    height: 100%;
    padding: 0px;
    overflow: hidden;
    text-align: left;
}

.jqx-menu-dropdown-column {
    float: left;
}
/*applied to the menu when it is horizontal. Sets the menu's background*/
.jqx-menu-horizontal {
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    text-align: center;
}
/*applied to the menu when it is vertical. Sets the menu's background*/
.jqx-menu-vertical {
    text-align: left;
}
/*applied to the sub menu. Sets the sub menu's background*/
.jqx-menu-dropdown {
    left: 100%;
    margin: 0px;
    padding: 0px;
    right: 0;
    overflow: hidden;
    display: none;
    float: none;
    width: 150px;
    border-style: solid;
    border-width: 1px;
    text-align: left;
    padding: 2px;
}
    /*applied to the sub menu's ul elements.*/
    .jqx-menu-dropdown ul {
        left: 100%;
        margin: 0px;
        padding: 0px;
        right: 0;
        overflow: hidden;
        border: none;
    }
/*applied to the menu's ul elements.*/
.jqx-menu ul {
    left: 100%;
    margin: 0px;
    padding: 2px;
    right: 0;
    overflow: hidden;
    background-color: transparent;
    border: none;
}
/*applied to the menu's li elements.*/
.jqx-menu li, .jqx-menu-dropdown li {
    line-height: 1.3 !important;
}
/*applied to the top level menu items.*/
.jqx-menu-item-top {
    border: 1px solid transparent;
    text-indent: 0;
    list-style: none;
    padding: 4px 8px 4px 8px;
    left: 100%;
    overflow: hidden;
    color: inherit;
    right: 0;
    margin: 0px 3px 0px 3px;
    cursor: pointer;
}
/*applied to the sub menu items.*/
.jqx-menu-item {
    border: 1px solid transparent;
    text-indent: 0;
    list-style: none;
    padding: 4px 8px 4px 8px;
    margin: 1px;
    margin-left: 3px;
    margin-right: 3px;
    float: none;
    overflow: hidden;
    left: 100%;
    color: inherit;
    right: 0;
    text-align: left;
    cursor: pointer;
}
/*applied to a sub menu item when the mouse is over the item.*/
.jqx-menu-item-hover {
    color: inherit;
    right: 0;
    list-style: none;
    margin: 1px;
    margin-left: 3px;
    margin-right: 3px;
    left: 100%;
    padding: 4px 8px 4px 8px;
    text-align: left;
    cursor: pointer;
}
/*applied to a top-level menu item when the mouse is over it.*/
.jqx-menu-item-top-hover {
    color: inherit;
    right: 0;
    list-style: none;
    margin: 0px 3px 0px 3px;
    left: 100%;
    padding: 4px 8px 4px 8px;
    top: 50%;
    cursor: pointer;
}
/*applied to a sub menu item when its sub menu is opened.*/
.jqx-menu-item-selected {
    right: 0;
    list-style: none;
    margin: 1px;
    margin-left: 3px;
    margin-right: 3px;
    left: 100%;
    padding: 4px 8px 4px 8px;
    text-align: left;
    cursor: pointer;
}
/*applied to a disabled sub menu item.*/
.jqx-menu-item-disabled {
}
/*applied to a top-level menu item when its sub menu is opened.*/
.jqx-menu-item-top-selected {
    right: 0;
    list-style: none;
    margin: 0px 1px 0px 1px;
    margin-left: 3px;
    margin-right: 3px;
    left: 100%;
    padding: 4px 8px 4px 8px;
    cursor: pointer;
}
/*applied to the menu separator items.*/
.jqx-menu-item-separator {
    text-indent: 0;
    background-color: #ddd;
    border: none;
    list-style: none;
    height: 1px;
    line-height: 0 !important;
    padding: 0px;
    margin: 1px 2px 1px 2px;
    float: none;
    overflow: hidden;
    left: 100%;
    color: inherit;
    right: 0;
    font-size: 1px;
}
/*applied to a sub menu item when it has sub menu items. Displays right arrow icon.*/
.jqx-menu-item-arrow-right {
    background-image: url(./images/icon-right.png);
}
/*applied to a sub menu item when it has sub menu items. Displays down arrow icon.*/
.jqx-menu-item-arrow-down {
    background-image: url(./images/icon-down.png);
}
/*applied to a sub menu item when it has sub menu items. Displays up arrow icon.*/
.jqx-menu-item-arrow-up {
    background-image: url(./images/icon-up.png);
}

.jqx-menu-minimized {
    position: relative;
}

.jqx-menu-title {
    position: relative;
    float: right;
    margin-top: 4px;
    padding-top: 4px;
    padding-right: 20px;
}

.jqx-menu-minimized-button {
    background-image: url('images/icon-menu-minimized.png');
    background-repeat: no-repeat;
    background-position: left center;
    height: 24px;
    width: 24px;
    padding: 0px;
    margin-left: 7px;
    margin-top: 4px;
    float: left;
}

.jqx-menu-ul-minimized {
    display: block !important;
    width: auto !important;
}

    .jqx-menu-ul-minimized ul {
        margin-top: 0px;
        margin-bottom: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        -webkit-box-shadow: none !important;
        -moz-box-shadow: none !important;
        -o-box-shadow: none !important;
        box-shadow: none !important;
    }

.jqx-menu-minimized li:last-child {
    padding-bottom: 0px;
    margin: 0px;
}

.jqx-menu-item-arrow-right, .jqx-menu-item-arrow-down, .jqx-menu-item-arrow-up, .jqx-menu-item-arrow-right {
    background-position: 100% 50%;
    background-repeat: no-repeat;
    padding-right: 0px;
    margin-right: -8px;
    width: 17px;
    height: 15px;
}
/*applied to a sub menu item when it has sub menu items. Displays left arrow icon.*/
.jqx-menu-item-arrow-left {
    padding-right: 0px;
    padding-left: 5px;
    width: 17px;
    height: 15px;
    background-image: url(./images/icon-left.png);
    background-position: 0 50%;
    background-repeat: no-repeat;
}
/*applied to a sub menu item when it has sub menu items and its sub menu is opened. Displays right arrow icon.*/
.jqx-menu-item-arrow-right-selected {
    background-image: url(./images/icon-right.png);
}
/*applied to a sub menu item when it has sub menu items and its sub menu is opened. Displays down arrow icon.*/
.jqx-menu-item-arrow-down-selected {
    background-image: url(./images/icon-down.png);
}
/*applied to a sub menu item when it has sub menu items and its sub menu is opened. Displays up arrow icon.*/
.jqx-menu-item-arrow-up-selected {
    background-image: url(./images/icon-up.png);
}

.jqx-menu-item-arrow-right-selected, .jqx-menu-item-arrow-up-selected, .jqx-menu-item-arrow-down-selected, .jqx-menu-item-arrow-top-right {
    padding-right: 0px;
    margin-right: -8px;
    width: 17px;
    height: 15px;
    background-position: 100% 50%;
    background-repeat: no-repeat;
}
/*applied to a top-level menu item when it has sub menu items. Displays right arrow icon.*/
.jqx-menu-item-arrow-top-right {
    background-image: url(./images/icon-right.png);
}
/*applied to a sub menu item when it has sub menu items and its sub menu is opened. Displays left arrow icon.*/
.jqx-menu-item-arrow-left-selected {
    background-image: url(./images/icon-left.png);
}
/*applied to a top-level menu item when it has sub menu items. Displays left arrow icon.*/
.jqx-menu-item-arrow-top-left {
    background-image: url(./images/icon-left.png);
}

.jqx-menu-item-arrow-left-selected, .jqx-menu-item-arrow-top-left {
    background-position: 0 50%;
    background-repeat: no-repeat;
    padding-right: 0px;
    padding-left: 5px;
    width: 17px;
    height: 15px;
}
/*applied to the anchor elements*/
.jqx-menu-item a:link, .jqx-menu-item a:visited, .jqx-menu-item a:hover, .jqx-menu-item-top a:link, .jqx-menu-item-top a:visited, .jqx-menu-item-top a:hover {
    font: inherit;
    text-decoration: none;
    color: inherit;
    outline: none;
    background-color: transparent;
}

/*applied to the menu widget when it is disabled.*/
.jqx-menu-disabled {
    cursor: default;
}
    /*applied to the anchor elements of all disabled menu items.*/
    .jqx-menu-disabled a:link {
        cursor: default;
        text-decoration: none;
    }

    .jqx-menu-disabled a:visited {
        cursor: default;
        text-decoration: none;
    }

    .jqx-menu-disabled a:hover {
        cursor: default;
    }

.jqx-menu-dropdown {
    right: -1px;
}

/*jqxtree Style*/
.jqx-tree {
    left: 100%;
    right: 0;
    float: none;
    margin: 0px;
    border-style: solid;
    border-width: 1px;
    padding: 0px;
    overflow: hidden;
    text-align: left;
    outline: none;
    white-space: nowrap;
    line-height: 14px !important;
}
/*applied to the jqxTree root UL element.*/
.jqx-tree-dropdown-root {
    left: 100%;
    padding: 0px 5px 0px 5px;
    right: 0;
    display: block;
    float: none;
    background-color: transparent;
    border-style: solid;
    border-width: 0px;
    border-color: #fff;
    text-align: left;
    outline: none;
    white-space: nowrap;
    margin: 0px;
}

.jqx-tree-dropdown-root-rtl {
    padding: 0px 5px 0px 0px;
    text-align: right;
    float: right;
    margin: 0px;
}

/*applied to the jqxTree UL elements.*/
.jqx-tree-dropdown {
    left: 100%;
    margin: 0px;
    padding: 0px 0px 0px 10px;
    right: 0;
    display: block;
    float: none;
    background-color: transparent;
    border-style: solid;
    border-width: 0px;
    border-color: transparent;
    text-align: left;
    outline: none;
}

.jqx-tree-dropdown-rtl {
    padding: 3px 0px 0px 0px;
    text-align: right;
}

/*applied to a tree item.*/
.jqx-tree-item {
    border: 1px solid transparent;
    text-indent: 0;
    list-style: none;
    padding: 3px;
    margin: 0px;
    float: none;
    overflow: hidden;
    left: 100%;
    right: 0;
    text-align: left;
    cursor: default;
    text-decoration: none;
}
/*applied to the jqxTree LI elements.*/
.jqx-tree-item-li {
    text-indent: 0;
    background-color: transparent;
    border: 0px solid transparent;
    list-style: none;
    left: 100%;
    right: 0;
    text-align: left;
    outline: none;
    margin: 0px;
    margin-top: 1px;
    padding: 0px;
}
/*applied to the last LI elements in an UL element.*/
.jqx-tree-item-u-last {
    margin: 0px 0px 0px 0px;
}
/*applied to a tree item when the mouse is over the item.*/
.jqx-tree-item-hover {
    color: inherit;
    right: 0;
    padding: 3px;
    margin: 0px;
    list-style: none;
    left: 100%;
    cursor: pointer;
    text-decoration: none;
}
/*applied to a tree item when the item is selected.*/
.jqx-tree-item-selected {
    color: inherit;
    right: 0;
    padding: 3px;
    margin: 0px;
    list-style: none;
    left: 100%;
    cursor: pointer;
    text-decoration: none;
}

.jqx-tree-item-rtl {
    text-align: right;
}

.jqx-tree-item-li-rtl {
    text-align: right;
}
/*applied to the anchor element of a tree item.*/
.jqx-tree-item a:link, .jqx-tree-item a:visited {
    text-indent: 0;
    background-color: transparent;
    border: 0px solid transparent;
    list-style: none;
    padding: 0px;
    margin: 0px;
    float: none;
    overflow: hidden;
    left: 100%;
    color: inherit;
    right: 0;
    text-align: left;
    cursor: pointer;
    text-decoration: none;
    outline: none;
}

.jqx-tree-item-selected, .jqx-tree-item-hover a:hover {
    color: inherit;
}

.jqx-tree-item-selected, .jqx-tree-item-hover a:visited {
    color: inherit;
}

/*applied to a tree item when the item is disabled.*/
.jqx-tree-item-disabled {
}
/*applied to a tree item when it has sub items and is collapsed. Displays an arrow icon next to the item.*/
.jqx-tree-item-arrow-collapse, .jqx-tree-item-arrow-collapse-hover {
    padding-right: 0px;
    width: 17px;
    height: 17px;
    background-image: url(./images/icon-right.png);
    background-position: 100% 50%;
    background-repeat: no-repeat;
    cursor: pointer;
}

.jqx-tree-item-arrow-collapse-rtl, .jqx-tree-item-arrow-collapse-hover-rtl {
    background-image: url(./images/icon-left.png);
}

/*applied to a tree item when it has sub items and is expanded. Displays an arrow icon next to the item.*/
.jqx-tree-item-arrow-expand, .jqx-tree-item-arrow-expand-hover {
    padding-right: 0px;
    width: 17px;
    height: 17px;
    background-image: url(./images/icon-down.png);
    background-position: 100% 50%;
    background-repeat: no-repeat;
    cursor: pointer;
}
/*applied to the tree when it is disabled.*/
.jqx-tree-disabled {
    cursor: default;
}
    /*applied to the anchor elements in a tree when it is disabled.*/
    .jqx-tree-disabled a:link {
        cursor: default;
        text-decoration: none;
    }

    .jqx-tree-disabled a:visited {
        cursor: default;
        text-decoration: none;
    }

    .jqx-tree-disabled a:hover {
        cursor: default;
    }


/*jqxTabs*/
.jqx-tabs {
    outline: none;
    margin: 0 0 0 0;
    padding: 0px;
    overflow: hidden;
    background: transparent;
    border: 1px solid transparent;
}
/*applied to the tab close button.*/
.jqx-tabs-close-button {
    outline: none;
    background-image: url(./images/close.png);
    cursor: pointer;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    position: relative;
    left: 5px;
}
/*applied to the tab close button when the tab is selected.*/
.jqx-tabs-close-button-selected {
    outline: none;
    background-image: url(./images/close.png);
    cursor: pointer;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
}
/*applied to the tab close button when the mouse is over the tab.*/
.jqx-tabs-close-button-hover {
    outline: none;
    background-image: url(./images/close.png);
    cursor: pointer;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
}

.jqx-tabs-close-button, .jqx-tabs-close-button-selected, .jqx-tabs-close-button-hover {
    background-repeat: no-repeat;
    background-position: center;
}
/*applied to the tab's left scroll arrow.*/
.jqx-tabs-arrow-left {
    outline: none;
    position: relative;
    z-index: 15;
    float: left;
    cursor: pointer;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    background-image: url('images/icon-left.png');
    background-repeat: no-repeat;
    background-position: center;
}
/*applied to the tab's right scroll arrow.*/
.jqx-tabs-arrow-right {
    outline: none;
    position: relative;
    z-index: 15;
    background-image: url('images/icon-right.png');
    background-repeat: no-repeat;
    background-position: center;
    float: left;
    cursor: pointer;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
}

.jqx-tabs-arrow-background {
    background: #eeeeee;
}
/*applied to the tab's title.*/
.jqx-tabs-title {
    outline: none;
    display: block;
    cursor: pointer;
    white-space: nowrap;
    left: 100%;
    right: 0;
    text-indent: 0px;
    list-style: none;
    border: 1px solid transparent;
    margin: 0px 2px 0px 0px;
    overflow: hidden;
    z-index: 1;
    padding-left: 13px;
    padding-right: 13px;
    padding-bottom: 5px;
    padding-top: 5px;
    background: transparent;
    height: auto;
    position: relative;
}

.jqx-tabs-title-bottom {
}

.jqx-tabs-title a:link {
    color: inherit;
    text-decoration: none;
}

.jqx-tabs-title a:hover {
    color: inherit;
    text-decoration: none;
}

.jqx-tabs-title a:active {
    color: inherit;
    text-decoration: none;
}

.jqx-tabs-title a:visited {
    color: inherit;
    text-decoration: none;
}
/*applied to the tab's title when the tab is selected and the jqxTab's position property is set to 'top' .*/
.jqx-tabs-title-selected-top {
    z-index: 99;
    outline: none;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid #fff;
    background-color: #fff;
    padding-bottom: 7px;
}
/*applied to the tab's title when the tab is selected and the jqxTab's position property is set to 'bottom' .*/
.jqx-tabs-title-selected-bottom {
    outline: none;
    border-top: 1px solid #fff;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    padding-top: 7px;
    padding-bottom: 5px;
    margin-top: -2px;
    background-color: #fff;
}
/*applied to the tab's title when the tab is hovered and the jqxTab's position property is set to 'top' .*/
.jqx-tabs-title-hover-top {
    outline: none;
    border: 1px solid transparent;
    padding-bottom: 5px;
}
/*applied to the tab's title when the tab is hovered and the jqxTab's position property is set to 'bottom' .*/
.jqx-tabs-title-hover-bottom {
    outline: none;
    border: 1px solid transparent;
    padding-top: 5px;
}
/*applied to the tab's title when the tab is disabled.*/
.jqx-tabs-title-disable {
}
/*applied to the tab's header.*/
.jqx-tabs-header {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 3px;
    padding-bottom: 1px;
    margin: 0px;
    border-top: 0px solid transparent;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
    border-bottom: 1px solid #aaa;
    background: #eeeeee;
}
/*applied to the tab's header when the position is bottom.*/
.jqx-tabs-header-bottom {
    padding-top: 1px;
    padding-bottom: 3px;
    border-top: 0px solid #aaa;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
    border-bottom: 1px solid transparent;
}
/*applied to the tab's header when the tab is collapsed.*/
.jqx-tabs-header-collapsed {
    border: 1px solid #aaa;
}
/*applied to the tab's header when the position is bottom and tab is collapsed.*/
.jqx-tabs-header-collapsed-bottom {
    border: 1px solid #aaa;
}

.jqx-tabs-collapsed {
    border: 0px solid transparent;
}

.jqx-tabs-collapsed-bottom {
    border: 0px solid transparent;
}
/*applied to the tab's selection tracker container element.*/
.jqx-tabs-selection-tracker-container {
    outline: none;
    position: relative;
    text-indent: 0px;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    width: 100%;
    background: transparent;
    height: 0px;
}
/*applied to the tab's selection tracker when the jqxTab's position property is set to 'top'.*/
.jqx-tabs-selection-tracker-top {
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    padding-bottom: 2px;
    background-color: #fff;
    outline: none;
    position: absolute;
    z-index: 10;
}
/*applied to the tab's selection tracker when the jqxTab's position property is set to 'bottom'.*/
.jqx-tabs-selection-tracker-bottom {
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    background-color: #fff;
    outline: none;
    position: absolute;
    z-index: 10;
    margin-top: -1px;
    padding-bottom: 2px;
}
/*applied to the tab's content element which represents a DIV element.*/
.jqx-tabs-content {
    outline: none;
    border-bottom: 0px solid transparent;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
    border-top: 0px solid transparent;
    text-align: left;
    clear: both;
}

.jqx-tabs-content-element {
    height: 100%;
    overflow: auto;
}

/*jqxCheckBox*/
.jqx-checkbox {
    overflow: hidden;
    text-align: left;
    border: none;
    outline: none;
    margin: 0 0 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
}
/*applied to the check box.*/
.jqx-checkbox-default {
    text-align: left;
    float: left;
    padding: 0px;
    border: 1px solid transparent;
    outline: none;
    margin: 0px 3px 0px 3px;
}
/*applied to the check box when the mouse cursor is over it.*/
.jqx-checkbox-hover {
    cursor: pointer;
    float: left;
    padding: 0px;
    border: 1px solid transparent;
    outline: none;
    margin: 0px 3px 0px 3px;
}
/*applied to the widget when it is disabled.*/
.jqx-checkbox-disabled {
    cursor: default;
    background: transparent;
    padding: 0px;
    border: none;
    outline: none;
    margin: 0px 0px 0px 0px;
}
/*applied to the check box when the widget is disabled.*/
.jqx-checkbox-disabled-box {
    float: left;
    padding: 0px;
    border: 1px solid transparent;
    outline: none;
    margin: 0px 3px 0px 3px;
}
/*applied to the check box when it is checked. Displays a check icon.*/
.jqx-checkbox-check-checked {
    float: left;
    background: transparent url(./images/check_black.png) center center no-repeat;
    padding: 0px;
    border: none;
    outline: none;
    margin: 0 0 0 0;
}
/*applied to the check box when the widget is disabled. Displays a disabled check icon.*/
.jqx-checkbox-check-disabled {
    float: left;
    background: transparent url(./images/check_disabled.png) center center no-repeat;
    padding: 0px;
    border: none;
    outline: none;
    margin: 0 0 0 0;
}
/*applied to the check box when its state is indeterminate.*/
.jqx-checkbox-check-indeterminate {
    float: left;
    background: transparent url(./images/check_indeterminate_black.png) center center no-repeat;
    padding: 0px;
    border: none;
    outline: none;
    margin: 0 0 0 0;
}
/*applied to the check box when its state is indeterminate and it is disabled.*/
.jqx-checkbox-check-indeterminate-disabled {
    float: left;
    background: transparent url(./images/check_indeterminate_disabled.png) center center no-repeat;
    padding: 0px;
    border: none;
    outline: none;
    margin: 0 0 0 0;
}
/*applied to the check box when its in rtl mode.*/
.jqx-checkbox-rtl {
    float: right;
}
/*jqxRadioButton*/
.jqx-radiobutton {
    overflow: hidden;
    text-overflow: ellipsis;
    border: none;
    outline: none;
    margin: 0 0 0 0;
    white-space: nowrap;
}
/*applied to the radio button.*/
.jqx-radiobutton-default {
    cursor: pointer;
    float: left;
    background: transparent url(./images/roundbg_classic_normal.png) left center scroll repeat-x;
    padding: 0px;
    border: 1px solid transparent;
    outline: none;
    margin: 1px 3px 0px 3px;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
}
/*applied to the radio button when the mouse is over it.*/
.jqx-radiobutton-hover {
    cursor: pointer;
    float: left;
    background: transparent;
    padding: 0px;
    border: 1px solid transparent;
    outline: none;
    margin: 1px 3px 0px 3px;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
}
/*applied to the radio button when it is disabled.*/
.jqx-radiobutton-disabled {
    cursor: default;
}
/*applied to the radio button's radio icon when it is disabled.*/
.jqx-radiobutton-disabled-box {
}
/*applied to the radio button when it is checked.*/
.jqx-radiobutton-check-checked {
    float: left;
    background: transparent url(./images/roundbg_check_black.png) left top no-repeat;
    padding: 0px;
    border: 1px solid transparent;
    outline: none;
    margin: 0 0 0 0;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    width: 7px;
    height: 7px;
    margin-left: 2px;
    margin-top: 2px;
}
/*applied to the radio button when it is checked and disabled.*/
.jqx-radiobutton-check-disabled {
}
/*applied to the radio button when it is in indeterminate state.*/
.jqx-radiobutton-check-indeterminate {
    float: left;
    background: transparent url(./images/roundbg_check_indeterminate.png) left top no-repeat;
    padding: 0px;
    border: none;
    outline: none;
    margin: 0 0 0 0;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    width: 6px;
    height: 6px;
    margin-left: 2px;
    margin-top: 2px;
}
/*applied to the radio button when it is in indeterminate state and disabled.*/
.jqx-radiobutton-check-indeterminate-disabled {
}
/*applied to the radio button when its in rtl mode.*/
.jqx-radiobutton-rtl {
    float: right;
}

/*jqxRating*/
.jqx-rating {
    margin: 0px;
    padding: 0px;
    outline: none;
    overflow: hidden;
}

.jqx-rating-image {
    border-width: 0px;
}

.jqx-rating-image-default {
    margin: 0px;
    padding: 0px;
    outline: none;
    background-color: transparent;
    background-repeat: no-repeat;
    cursor: pointer;
    background-image: url(./images/star.png);
    overflow: hidden;
}
/*applied to the rating when it is hovered.*/
.jqx-rating-image-hover {
    background-color: transparent;
    padding: 0px;
    background-repeat: no-repeat;
    cursor: pointer;
    background-image: url(./images/star_hover.png);
    overflow: hidden;
}
/*applied to the rating when it is disabled.*/
.jqx-rating-image-backward {
    background-color: transparent;
    padding: 0px;
    background-repeat: no-repeat;
    cursor: pointer;
    background-image: url(./images/star_disabled.png);
    overflow: hidden;
}
/*jqxCalendar Style*/
.jqx-calendar {
    border: 1px solid transparent;
    -webkit-appearance: none;
    overflow: hidden;
    outline: none;
    padding: 0px;
    margin: 0 0 0 0;
    cursor: default;
    border-collapse: separate;
}

    .jqx-calendar .jqx-widget-header {
    }

    .jqx-calendar > div {
        padding: 0px;
        box-sizing: border-box;
    }

.jqx-calendar-light > div {
    padding: 10px;
}

.jqx-calendar td {
    padding-top: 0px;
    padding-bottom: 0px;
    border-collapse: separate;
    border-spacing: 1px;
    font-size: 13px;
    line-height: 1.231;
    height: auto;
}

.jqx-calendar table {
    margin: 0px;
    padding: 0px;
    border-collapse: separate;
    border-spacing: 1px;
    font-size: 13px;
    line-height: 1.231;
}
/*applied to the calendar's row header. This header displays the week numbers.*/
.jqx-calendar-row-header {
    outline: none;
    border: 0px solid #f2f2f2;
    padding: 0;
    margin: 0 0 0 0;
    cursor: default;
}
/*applied to the calendar's column. This header displays the day names.*/
.jqx-calendar-column-header {
    outline: none;
    text-align: right;
    padding: 3px;
    border-top: none;
    border-bottom: 1px solid transparent;
    border-left: none;
    border-right: none;
    margin: 0 0 0 0;
    cursor: default;
}
/*applied to the calendar's top-left header. This header is displayed before the day names and above the week numbers.*/
.jqx-calendar-top-left-header {
    outline: none;
    border: 0px solid #f2f2f2;
    margin: 0 0 0 0;
    cursor: default;
}
/*applied to the calendar's navigation buttons.*/
.jqx-calendar-title-navigation {
    cursor: pointer;
    background-color: transparent;
    margin: 0;
    padding: 0;
    border: none;
    width: 25px;
    height: 25px;
}
/*applied to the month table*/
.jqx-calendar-month {
    margin: 0;
    padding: 0;
    border: none;
    width: 100%;
}
/*applied to the calendar's title content.*/
.jqx-calendar-title-content {
    cursor: pointer;
    text-align: center;
}
/*applied to the calendar's navigation title.*/
.jqx-calendar-title-header {
    border-left: none;
    border-top: none;
    border-right: none;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin: 0 0 0 0;
}

    .jqx-calendar-title-header td {
        padding-left: 0px;
        padding-right: 0px;
        border: none;
    }
/*applied to the calendar's navigation title when the calendar is disabled.*/
.jqx-calendar-title-header-disabled {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin: 0 0 0 0;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
    border-top: 0px solid transparent;
}
/*applied to a calendar cell.*/
.jqx-calendar-cell {
    border: 1px solid transparent;
    margin: 0px;
    outline: none;
    text-align: center;
    padding: 1px;
    cursor: pointer;
    background-color: transparent;
}

    .jqx-calendar-cell td {
        padding: 0;
        margin: 0;
    }
/*applied to a calendar cell in decade and year views.*/
.jqx-calendar-cell-decade, .jqx-calendar-cell-year {
    text-align: center;
}

.jqx-calendar .jqx-fill-state-normal {
    background: transparent;
    border: 1px solid transparent;
}

/*applied to a calendar cell when the cell is hidden. A cell can be hidden when the showOtherMonthDays property is false and the calendar hides the cells with dates from the other months*/
.jqx-calendar-cell-hidden {
    margin: 0 0 0 0;
    visibility: hidden;
    cursor: default;
}
/*applied to a calendar cell when the cell's date is a weekend date.*/
.jqx-calendar-cell-weekend {
    color: #898989 !important;
    margin: 0 0 0 0;
    cursor: pointer;
}
/*applied to a calendar cell when the cell's date is a special date(holiday, vacation, birthay. See the SpecialDates demo).*/
.jqx-calendar-cell-specialDate {
    color: #FF0000 !important;
    background-color: transparent;
    border: 1px solid #fff;
    margin: 0 0 0 0;
    cursor: pointer;
}
/*applied to a calendar cell when the cell's date is a restricted date.*/
.jqx-calendar-cell-restrictedDate {
    background-color: rgba(251, 132, 153, 1);
    border: 1px solid #fff;
    margin: 0 0 0 0;
    cursor: pointer;
}

.jqx-calendar-cell-selected-invalid {
    color: #FFF !important;
    background: #FF0000 !important;
    border: 1px solid #FF0000 !important;
}
/*applied to a calendar cell when the cell's date is the today date.*/
.jqx-calendar-cell-today {
    color: #898989;
    background-color: #ffffdb;
    border: 1px solid #fdc066;
    margin: 0 0 0 0;
    cursor: pointer;
}
/*applied to a calendar cell when the cell is hovered.*/
.jqx-calendar-cell-hover {
    margin: 0 0 0 0;
    cursor: pointer;
}
/*applied to a calendar cell when the cell is selected.*/
.jqx-calendar-cell-selected {
    margin: 0 0 0 0;
    cursor: pointer;
}
/*applied to a calendar cell when the cell's date represents a date from the previous or next month.*/
.jqx-calendar-cell-othermonth {
    color: #898989 !important;
    margin: 0 0 0 0;
    cursor: pointer;
}
/*applied to a calendar cell when the calendar is disabled.*/
.jqx-calendar-cell-disabled {
    color: #ccc;
}
/*applied to a calendar cell when the cell is from the row header that displays the week numbers.*/
.jqx-calendar-row-cell {
    outline: none;
    text-align: center;
    border: 1px solid transparent;
    margin: 0 0 0 0;
    cursor: default;
}
/*applied to a calendar cell when the cell is from the row header that displays the week numbers and the calendar is disabled.*/
.jqx-calendar-row-cell-disabled {
}

.jqx-calendar-footer {
    margin: 5px;
    color: inherit;
    font-size: 13px;
}

.jqx-calendar-title-container {
    overflow: hidden;
}
/*applied to a calendar cell when the cell is from the column header that displays the day names.*/
.jqx-calendar-column-cell {
    overflow: hidden;
    text-align: center;
    outline: none;
    font-weight: normal;
    padding: 1px;
    border: 1px solid transparent;
    margin: 0 0 0 0;
    cursor: default;
}
/*applied to a calendar cell when the cell is from the column header that displays the day names and the calendar is disabled.*/
.jqx-calendar-column-cell-disabled {
}
/*applied to the calendar's cell area element.*/
.jqx-calendar-view {
    -webkit-appearance: none;
    outline: none;
    padding: 0px;
    border: 0px solid #fff;
    margin: 0 0 0 0;
    cursor: default;
}

/*jqxExpander and jqxNavigationBar Style*/
.jqx-expander {
}
/*applied to the Expander's header and content*/
.jqx-expander-header, .jqx-expander-content {
    border-left-width: 1px;
    border-right-width: 1px;
    border-left-style: solid;
    border-right-style: solid;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    outline: 0;
}
/*applid to the content of the expander header.*/
.jqx-expander-header-content {
    padding-left: 3px;
}
/*applid to the content of the expander header.*/
.jqx-expander-header-content-rtl {
    padding-right: 3px;
}

.jqx-expander-content {
    padding-top: 0px;
    padding-bottom: 0px;
    overflow: auto;
    outline: 0;
}
/*applied to the Expander's header and its content when it is on the top of the header*/
.jqx-expander-header, .jqx-expander-content-top {
    border-top-width: 1px;
    border-top-style: solid;
}
/*applied to the Expander's header and its content when it is on the bottom of the header*/
.jqx-expander-header, .jqx-expander-content-bottom {
    border-bottom-width: 1px;
    border-bottom-style: solid;
}
/*applied to the Expander's header*/
.jqx-expander-header {
    cursor: pointer;
    overflow: hidden;
}
/*applied to the Expander's header when the expander is disabled or the toggleMode is "none"*/
.jqx-expander-header-disabled {
    cursor: default;
}
/*applied to the Expander's arrow*/
.jqx-expander-arrow {
    width: 17px;
    height: 17px;
    position: relative;
}
/*applied to the Expander's content if it is empty*/
.jqx-expander-content-empty {
    border-width: 0px;
    padding: 0px;
}

/*applied to jqxNavigationBar. The NavigationBar internally uses jqxExpander's classes and inherits all header and content style settings from the Expander.*/
.jqx-navigationbar {
    border: none;
    margin: 0px;
    -webkit-appearance: none;
    outline: none;
}
/*jqxPanel Style*/
.jqx-panel {
    border: 1px solid transparent;
    -webkit-appearance: none;
    outline: none;
    padding: 0px;
    margin: 0px;
    cursor: default;
    overflow: hidden;
}
/*applied to the Panel's element displayed when the horizontal and vertical scrollbars are visible and the element is positioned below the vertical scrollbar and on the right
of the horizontal scrollbar.*/
.jqx-panel-bottomright {
}
/*applied to the DropDownButton's Popup.*/
.jqx-dropdownbutton-popup {
    -webkit-appearance: none;
    outline: none;
    border: 1px solid transparent;
}

.jqx-listbox {
    -webkit-appearance: none;
    outline: none;
    border: 1px solid transparent;
    padding: 0px;
    margin: 0px;
    cursor: default;
    overflow: hidden;
}
/*applied to the ListBox's element displayed when the horizontal and vertical scrollbars are visible and the element is positioned below the vertical scrollbar and on the right
of the horizontal scrollbar.*/
.jqx-listbox-bottomright {
}
/*applied to the ListBox's groups.*/
.jqx-listitem-state-group {
    white-space: nowrap;
    padding: 5px 2px 5px 2px;
    background: transparent;
    font-weight: bold;
    text-align: left;
    outline: none;
    overflow: hidden;
    vertical-align: middle;
    cursor: default;
}
/*applied to a list item in default state.*/
.jqx-listitem-state-normal {
    white-space: nowrap;
    padding: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid transparent;
    outline: none;
    visibility: inherit;
    display: inherit;
    text-align: left;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    cursor: default;
}
/*applied to a list item in hovered state.*/
.jqx-listitem-state-hover {
    white-space: nowrap;
    background: transparent;
    padding: 3px 3px 3px 3px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid transparent;
}
/*applied to a list item when the item is selected.*/
.jqx-listitem-state-selected {
    white-space: nowrap;
    padding: 3px 3px 3px 3px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid transparent;
}

.jqx-listbox-feedback {
    height: 1px;
    border-top: 1px dashed #000;
}

.jqx-listitem-element {
    -ms-touch-action: none;
    border: none;
    width: 100%;
    height: 100%;
    align: left;
    valign: top;
    position: absolute;
}
/*jqxComboBox Style*/
/*applied to the jqxComboBox's content which displays the text.*/
.jqx-combobox-content {
    padding: 0px;
    overflow: hidden;
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 0px;
    margin: 0px;
    text-align: left;
    vertical-align: middle;
    white-space: nowrap;
    -webkit-appearance: none;
    outline: none;
    border: none;
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-right: 1px solid transparent;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-background-clip: padding-box;
}

.jqx-combobox-content-rtl {
    border: none;
    -moz-border-radius-topleft: 0px;
    -webkit-border-top-left-radius: 0px;
    border-top-left-radius: 0px;
    -moz-border-radius-bottomleft: 0px;
    -webkit-border-bottom-left-radius: 0px;
    border-bottom-left-radius: 0px;
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 0px;
    border-top-right-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 0px;
    border-bottom-right-radius: 3px;
    border-right: none;
    border-left: 1px solid transparent;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-background-clip: padding-box;
}
/*applied to the ComboBox's input field.*/
.jqx-combobox-input {
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    padding: 0 0 0 0;
    outline: none;
    border: 0px solid #aaa;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-background-clip: padding-box;
}

    .jqx-combobox-input::-ms-clear {
        display: none;
    }
/*applied to the ComboBox's content when the widget is disabled.*/
.jqx-combobox-content-disabled {
    color: #AAA;
}
/*applied to the ComboBox's arrow button.*/
.jqx-combobox-arrow-normal, .jqx-action-button {
    -webkit-appearance: none;
    outline: none;
    overflow: hidden;
    margin: 0 0 0 0;
    border: none;
    cursor: pointer;
}
/*applied to the ComboBox's arrow button*/
.jqx-combobox-arrow-hover, .jqx-combobox-arrow-selected {
    -webkit-appearance: none;
    outline: none;
    overflow: hidden;
    margin: 0 0 0 0;
}
/*applied to the ComboBox in normal state.*/
.jqx-combobox-state-normal {
    -webkit-appearance: none;
    outline: none;
    border: 1px solid transparent;
    overflow: hidden;
    margin: 0 0 0 0;
}
/*applied to the ComboBox when the mouse is over the widget.*/
.jqx-combobox-state-hover {
    overflow: hidden;
    border: 1px solid transparent;
    margin: 0 0 0 0;
}
/*applied to the ComboBox when the popup ListBox is shown.*/
.jqx-combobox-state-selected {
    overflow: hidden;
    border: 1px solid transparent;
    margin: 0 0 0 0;
}
/*applied to the ComboBox when the widget is focused.*/
.jqx-combobox-state-focus {
    border: 1px solid transparent;
}

.jqx-combobox-multi-item {
    padding: 1px 3px;
    margin: 2px;
    white-space: nowrap;
    border-style: solid;
    font-weight: normal;
    overflow: hidden;
    text-align: left;
}

    .jqx-combobox-multi-item a:link, .jqx-combobox-multi-item a:visited {
        color: inherit;
        display: block;
        outline: 0 none;
        text-decoration: none;
        cursor: pointer;
    }
/*applied to the ComboBox when the widget is disabled.*/
.jqx-combobox-state-disabled {
    white-space: nowrap;
    margin: 0px;
    color: #AAA;
}
/*jqxSelect Style*/
.jqx-select {
    overflow: hidden;
    padding-left: 3px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 0px;
    -webkit-appearance: none;
    outline: 0 none;
}

.jqx-select-content {
    cursor: pointer;
    text-overflow: ellipsis;
}

.jqx-select-container {
    padding: 0;
}

.jqx-select-item {
    padding: 3px;
    margin: 1px;
    white-space: normal !important;
    cursor: default;
}
/*jqxDropDownList Style*/
/*applied to the dropdownlist's content element which displays the selected item's text or html.*/
.jqx-dropdownlist-content {
    padding: 0px;
    overflow: hidden;
    padding-left: 4px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 0px;
    text-align: left;
    vertical-align: middle;
    white-space: nowrap;
    -webkit-appearance: none;
    outline: 0 none;
    border: none;
}

.jqx-dropdownlist-content-rtl {
    padding-left: 0px;
    padding-right: 3px;
}

.jqx-dropdownlist .jqx-icon-arrow-down {
    width: 13px;
}

.jqx-dropdownlist .jqx-icon-arrow-up {
    width: 13px;
}

.jqx-combobox .jqx-icon-arrow-down {
    width: 13px;
}

.jqx-combobox .jqx-icon-arrow-up {
    width: 13px;
}
/*applied to the dropdownlist's content element which displays the selected item's text or html and the widget is disabled. */
.jqx-dropdownlist-content-disabled {
}
/*applied to the dropdownlist in default state.*/
.jqx-dropdownlist-state-normal {
    -webkit-appearance: none;
    outline: none;
    border: 1px solid transparent;
    overflow: hidden;
    margin: 0 0 0 0;
    cursor: pointer;
}
/*applied to the dropdownlist in hovered state.*/
.jqx-dropdownlist-state-hover {
    overflow: hidden;
    border: 1px solid transparent;
    margin: 0 0 0 0;
    cursor: pointer;
}
/*applied to the dropdownlist in selected state.*/
.jqx-dropdownlist-state-selected {
    overflow: hidden;
    background-color: transparent;
    border: 1px solid transparent;
    margin: 0 0 0 0;
    cursor: pointer;
}
/*applied to the dropdownlist in focused state.*/
.jqx-dropdownlist-state-focus {
    border: 1px solid #909090;
}
/*applied to the dropdownlist in disabled state.*/
.jqx-dropdownlist-state-disabled {
}
/*jqxScrollBar Style*/
.jqx-scrollbar {
    background: transparent;
    margin: 0;
    padding: 0;
    border: none;
}
/*applied to the scrollbar in default state.*/
.jqx-scrollbar-state-normal {
    background: #efefef;
    margin: 0px;
    padding: 0px;
    border: 1px solid transparent;
}
/*applied to the scrollbar buttons in default state.*/
.jqx-scrollbar-button-state-normal {
    margin: 0px;
    padding: 0px;
    border: 1px solid transparent;
}
/*applied to the scrollbar buttons in hovered state.*/
.jqx-scrollbar-button-state-hover {
    margin: 0px;
    padding: 0px;
    border: 1px solid transparent;
}
/*applied to the scrollbar buttons in pressed state.*/
.jqx-scrollbar-button-state-pressed {
    margin: 0px;
    padding: 0px;
    border: 1px solid transparent;
}
/*applied to the horizontal scrollbar thumb in default state.*/
.jqx-scrollbar-thumb-state-normal-horizontal {
    margin: 0px;
    padding: 0px;
    background: transparent;
    border: 1px solid transparent;
    touch-action: none;
}
/*applied to the horizontal scrollbar thumb in hovered state.*/
.jqx-scrollbar-thumb-state-hover-horizontal {
    margin: 0px;
    padding: 0px;
    border: 1px solid transparent;
}
/*applied to the horizontal scrollbar thumb in default state.*/
.jqx-scrollbar-thumb-state-pressed-horizontal {
    margin: 0px;
    padding: 0px;
    border: 1px solid transparent;
}
/*applied to the vertical scrollbar thumb in default state.*/
.jqx-scrollbar-thumb-state-normal {
    margin: 0px;
    padding: 0px;
    border: 1px solid transparent;
    touch-action: none;
}
/*applied to the vertical scrollbar thumb in default state.*/
.jqx-scrollbar-thumb-state-hover {
    margin: 0px;
    padding: 0px;
    background: transparent;
    border: 1px solid transparent;
}
/*applied to the vertical scrollbar thumb in default state.*/
.jqx-scrollbar-thumb-state-pressed {
    margin: 0px;
    padding: 0px;
    background: #d1d1d1;
    border: 1px solid #909090;
}

/* jqxWindow */
.jqx-window {
    position: absolute;
    overflow: hidden;
    border: 1px solid transparent;
}
/*applied to the jqxWindow when it’s disabled*/
.jqx-window-disabled {
}
/*applied to the window’s header*/
.jqx-window-header {
    outline: none;
    border-width: 0px;
    border-bottom: 1px solid transparent;
    overflow: hidden;
    padding: 7px;
    height: auto;
    white-space: nowrap;
    overflow: hidden;
}
/*applied to the window’s header when the window is disabled*/
.jqx-window-header-disabled {
}
/*applied to the window’s content*/
.jqx-window-content {
    outline: none;
    overflow: auto;
    text-align: left;
    background-color: transparent;
    padding: 5px;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}
/*applied to the window’s content when the window is disabled*/
.jqx-window-content-disabled {
    outline: none;
    overflow: auto;
    text-align: left;
    padding: 5px;
}
/*applied to the window’s close button*/
.jqx-window-close-button {
    cursor: pointer;
    background-image: url(./images/close.png);
    background-repeat: no-repeat;
    margin-top: 2px;
}
/*applied to the window's collapse button*/
.jqx-window-collapse-button {
    background-image: url(./images/icon-up.png);
    cursor: pointer;
    margin-top: 3px;
}
/*applied to the window's collapse button when the window is collapsed*/
.jqx-window-collapse-button-collapsed {
    margin-top: 3px;
    cursor: pointer;
    background-image: url(./images/icon-down.png);
}
/*setting styles of the modal window’s background*/
.jqx-window-modal {
    background-color: Black;
}

.jqx-window-close-button-hover {
}

.jqx-docking {
    overflow: hidden;
}

.jqx-docking-panel {
    margin: 0px;
    padding: 0px;
    min-height: 100px;
}

.jqx-docking-drop-indicator {
    border-style: dashed;
    border-width: 1px;
    margin: 0px;
}

.jqx-docking-window {
    margin: 0px;
}

/* jxSlider */
/* applied to jqxSlider widget */
.jqx-slider {
    outline: none;
    clear: both;
    position: relative;
    overflow: hidden;
}
/*applied to the ticks which can be in the top/bottom/both sides of the jqxSlider*/
.jqx-slider-tick {
    border-left: 1px solid transparent;
    z-index: 9;
}
/*applied to the ticks when the slider is in horizontal mode*/
.jqx-slider-tick-horizontal {
    width: 1px;
    border-left: none;
    margin-top: 1px;
}
/*applied to the ticks when the slider is in vertical mode*/
.jqx-slider-tick-vertical {
    height: 1px;
    border-top: none;
}
/*applied to the ticks container*/
.jqx-slider-tickscontainer {
    z-index: 10;
    position: relative;
    margin-top: 0px;
    margin-bottom: 0px;
    z-index: 9;
}
/*applied to the jqxSlider thumb used for changing/indicating current value*/
.jqx-slider-slider {
    cursor: pointer;
    width: 20px;
    height: 20px;
    z-index: 15;
    position: absolute;
    left: 0px;
    top: 0px;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
}

.jqx-slider-button {
    cursor: pointer;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    box-sizing: content-box !important;
    padding: 3px !important;
}
/*applied to the slider's thumb when the slider is in horizontal mode*/
.jqx-slider-slider-horizontal {
    margin-top: -9px;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    border: 1px solid transparent;
}
/*applied to the slider's thumb when the slider is in vertical mode*/
.jqx-slider-slider-vertical {
    margin-left: -9px;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    border: 1px solid transparent;
}
/*applied to the track where is located the thumb*/
.jqx-slider-track {
    height: 4px;
    float: left;
    position: relative;
    z-index: 20;
    cursor: pointer;
}
/*used in horizontal slider*/
.jqx-slider-track-horizontal {
    height: 4px;
    margin-top: 2px;
}
/*used in vertical slider*/
.jqx-slider-track-vertical {
    width: 4px;
}
/*applied to the div which is between the thumbs in a range slider or from beginning of the track to the current thumb position in the default slider*/
.jqx-slider-rangebar {
}

/* jqxSplitter */
.jqx-splitter {
    overflow: hidden;
    border: 1px solid transparent;
    position: relative;
}
/* Split bar's class when the jqxSplitter is with horizontal orientation */
.jqx-splitter-splitbar-vertical {
    border: 1px solid transparent;
    border-top-width: 0px;
    border-bottom-width: 0px;
    cursor: e-resize;
    cursor: col-resize;
    position: absolute;
}
/* Split bar's mouse over state */
.jqx-splitter-splitbar-hover {
    background-color: transparent;
}
/* Split bar's class when the jqxSplitter is with horizontal orientation. */
.jqx-splitter-splitbar-horizontal {
    position: absolute;
    border: 1px solid transparent;
    border-left-width: 0px;
    border-right-width: 0px;
    cursor: n-resize;
    cursor: row-resize;
}
/* This class is applied to the splitter when it's dragged to it's max left/right boundary */
.jqx-splitter-splitbar-invalid {
    background: #FF0000 !important;
}
/* This class is added to split bar's collapse button when the splitter is with vectical orientation. */
.jqx-splitter-collapse-button-horizontal {
    cursor: pointer;
    width: 45px;
    height: 5px;
}
/* This class is added to split bar's collapse button when the splitter is with horizontal orientation. */
.jqx-splitter-collapse-button-vertical {
    cursor: pointer;
    width: 5px;
    height: 45px;
}
/* This class is added when the user disable the splitter. */
.jqx-splitter-splitbar-collapsed {
    cursor: default;
}
/* Applied to the jqxSplitter panels. */
.jqx-splitter-panel {
    border: none;
    outline: none;
    position: absolute;
    overflow: hidden;
}
/* This class is added to a nested splitter.*/
.jqx-splitter-nested, .jqx-splitter-panel-nested {
    border: none;
    overflow: hidden;
}

.jqx-draggable {
}

.jqx-draggable-dragging {
    border: 0px solid black;
}

.jqx-draggable-disabled {
    cursor: default;
}

.jqx-validator-hint {
    height: 18px;
    width: auto;
    display: inline-block;
    cursor: pointer;
    padding-left: 5px;
    padding-right: 5px;
    font-family: Verdana;
    font-size: 13px;
    border: 1px solid #6E0000;
    background-color: #942724;
    color: #fff;
    z-index: 99999;
    white-space: nowrap;
}

.jqx-validator-hint-arrow {
    z-index: 99999;
    height: 9px;
    width: 9px;
    padding: 0px;
    margin: 0px;
    background-color: transparent;
    background-image: url(./images/multi-arrow.gif);
}

.jqx-validator-error-label {
    color: #dd4b39;
    vertical-align: top;
    text-align: left;
    display: block;
    cursor: default;
    line-height: 17px;
}

.jqx-validator-error-element {
    border-color: #dd4b39 !important;
}

.jqx-switchbutton {
    outline: none;
    overflow: hidden;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
}
.jqx-switchbutton[aria-disabled="true"] {
    opacity: 0.5;
    pointer-events: none;
}
.jqx-switchbutton-thumb {
    display: inline-block;
    cursor: pointer;
    border: 1px solid #ccc;
}

.jqx-switchbutton-label {
    font-size: 15px;
    font-family: Sans-Serif;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
}

.jqx-switchbutton-wrapper {
}

.jqx-switchbutton-label-on {
}

.jqx-switchbutton-label-off {
}

/*left, right, up and down arrow icons.*/
.jqx-left-arrow {
    background: transparent;
    background-image: url('images/icon-left.png');
    cursor: pointer;
}

.jqx-right-arrow {
    background: transparent;
    background-image: url('images/icon-right.png');
    cursor: pointer;
}

.jqx-icon-delete {
    background-image: url('images/icon-delete.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-edit {
    background-image: url('images/icon-edit.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-save {
    background-image: url('images/icon-save.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-cancel {
    background-image: url('images/icon-cancel.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-arrow-up, .jqx-icon-arrow-up-hover, .jqx-icon-arrow-up-selected {
    background-image: url('images/icon-up.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-arrow-down, .jqx-icon-arrow-down-hover, .jqx-icon-arrow-down-selected {
    background-image: url('images/icon-down.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-arrow-left, .jqx-icon-arrow-left-hover, .jqx-icon-arrow-left-selected {
    background-image: url('images/icon-left.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-arrow-right, .jqx-icon-arrow-right-hover, .jqx-icon-arrow-right-selected {
    background-image: url('images/icon-right.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-arrow-first, .jqx-icon-arrow-first-hover, .jqx-icon-arrow-first-selected {
    background-image: url('images/icon-first.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-arrow-last, .jqx-icon-arrow-last-hover, .jqx-icon-arrow-last-selected {
    background-image: url('images/icon-last.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-arrow-up-hover {
    background-image: url('images/icon-up.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-arrow-down-hover {
    background-image: url('images/icon-down.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-arrow-left-hover {
    background-image: url('images/icon-left.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-arrow-right-hover {
    background-image: url('images/icon-right.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-arrow-up-selected {
    background-image: url('images/icon-up.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-arrow-down-selected {
    background-image: url('images/icon-down.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-arrow-left-selected {
    background-image: url('images/icon-left.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-arrow-right-selected {
    background-image: url('images/icon-right.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-close {
    cursor: pointer;
    background-image: url(./images/close.png);
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-close-white {
    background-image: url(./images/close_white.png);
}

.jqx-icon-close-hover {
    cursor: pointer;
    background-image: url(./images/close.png);
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-search {
    cursor: pointer;
    background-image: url(./images/search.png);
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 1px;
    background-size: 13px;
    width: 13px;
    height: 13px;
}

.jqx-icon-plus {
    background-image: url(./images/plus.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
    width: 16px;
    height: 16px;
}

.jqx-icon-plus-alt, .jqx-icon-plus-alt-white {
    background-image: url(./images/icon-plus.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
    width: 16px;
    height: 16px;
}

.jqx-icon-plus-alt-white {
    background-image: url(./images/icon-plus-white.png);
}
/* jqxScrollView */
.jqx-scrollview-button {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    display: inline-block;
    margin: 2px;
    cursor: pointer;
    background-color: #aaaeaf;
}

.jqx-scrollview {
    background-color: #000;
    -ms-touch-action: auto !important;
}

.jqx-icon-calendar, .jqx-icon-calendar-hover, .jqx-icon-calendar-pressed {
    background-image: url('images/icon-calendar.png');
    background-repeat: no-repeat;
    background-position: left top;
    position: relative;
    width: 15px !important;
    height: 15px !important;
    margin-top: -7.5px !important;
}
/*applied to Inputs*/
.jqx-input-widget {
    box-sizing: border-box !important;
}

.jqx-input {
    overflow: hidden;
    background-color: inherit;
    border-color: #c7c7c7;
}

.jqx-input-invalid {
    color: red !important;
}

.jqx-input-group {
    overflow: hidden;
}

.jqx-input-group-addon {
    border: 1px solid transparent;
    position: relative;
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    box-sizing: border-box !important;
    padding: 2px 8px !important;
    float: left;
    white-space: nowrap;
    vertical-align: middle;
}

    .jqx-input-group-addon:first-child {
        border-right-width: 0px;
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }

    .jqx-input-group-addon:last-child {
        border-left-width: 0px;
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }

    .jqx-input-group-addon:not(:last-child):not(:first-child) {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-left-width: 0px;
    }

.jqx-input-group .jqx-input {
    padding: 2px 1px !important;
    border-left-width: 1px !important;
    border-right-width: 1px !important;
}

.jqx-editor {
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
    overflow: hidden;
    box-sizing: border-box !important;
}

.jqx-editor-window {
    overflow: hidden;
}

.jqx-editor-inline {
    padding: 0px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
}

    .jqx-editor-inline p {
        margin: 0px;
        padding: 0 .2em;
    }

.jqx-editor-inline-focus {
    border-width: 1px;
    border-style: solid;
    overflow: auto;
}

.jqx-editor iframe {
    border: 0;
    margin: 0;
    padding: 0;
    background: #fff;
    display: inline;
    vertical-align: top;
    width: 100%;
    height: 100%;
}

.jqx-editor-color-bar {
    height: 3px;
}

.jqx-editor-color-picker table {
    margin: 0;
    padding: 0 4px 4px;
    empty-cells: show;
    border-collapse: separate;
}

.jqx-editor-color-picker td {
    border-collapse: separate;
    border: 1px solid transparent;
    cursor: pointer;
    margin: 0;
    position: relative;
}

    .jqx-editor-color-picker td:hover {
        border: 1px solid #000;
    }

.jqx-editor-color-picker-selected-cell {
    outline: 1px solid #000;
}

.jqx-editor-color-picker td div {
    width: 16px;
    height: 16px;
}

.jqx-editor-content {
    width: 100%;
    border-style: solid;
    border-width: 1px;
    outline: 0;
    left: 0px;
    position: relative;
    box-sizing: border-box !important;
}

.jqx-editor-container {
    position: relative;
    padding: 4px;
    padding-top: 0px;
    width: 100%;
    height: 100%;
    box-sizing: border-box !important;
}

.jqx-editor-toolbar-container {
    top: 0px;
    left: 0px;
    box-sizing: border-box !important;
}

.jqx-editor-toolbar-inline {
    padding: 0px 4px;
    border-width: 1px;
    border-style: solid;
}

.jqx-editor-toolbar {
    margin: 0;
    padding: 0px;
    padding-bottom: 4px;
}

.jqx-editor-toolbar-icon {
    height: 21px;
    width: 21px;
    vertical-align: middle;
    opacity: .667;
    margin: 2px 0;
    background: url('images/html_editor.png') no-repeat;
}

.jqx-editor-toolbar-icon-bold {
    background-position: 0px 0px !important;
}

.jqx-editor-toolbar-icon-italic {
    background-position: -21px 0px !important;
}

.jqx-editor-toolbar-icon-underline {
    background-position: -42px 0px !important;
}

.jqx-editor-toolbar-icon-fontname {
    background-position: -63px 0px !important;
}

.jqx-editor-toolbar-icon-fontsize {
    background-position: -84px 0px !important;
}

.jqx-editor-toolbar-icon-createlink {
    background-position: -147px 0px !important;
}

.jqx-editor-toolbar-icon-insertorderedlist {
    background-position: -168px 0px !important;
}

.jqx-editor-toolbar-icon-insertunorderedlist {
    background-position: -189px 0px !important;
}

.jqx-editor-toolbar-icon-outdent {
    background-position: -210px 0px !important;
}

.jqx-editor-toolbar-icon-indent {
    background-position: -231px 0px !important;
}

.jqx-editor-toolbar-icon-insertimage {
    background-position: -337px 0px !important;
}

.jqx-editor-toolbar-icon-justifyleft {
    background-position: -252px 0px !important;
}

.jqx-editor-toolbar-icon-justifycenter {
    background-position: -273px 0px !important;
}

.jqx-editor-toolbar-icon-justifyright {
    background-position: -294px 0px !important;
}

.jqx-editor-toolbar-icon-removeformat {
    background-position: -316px 0px !important;
}

.jqx-editor-toolbar-icon-viewsource {
    background-position: -359px 0px !important;
}

.jqx-editor-toolbar-icon-forecolor {
    background-position: -105px 0px !important;
    height: 15px;
}

.jqx-editor-toolbar-icon-backcolor {
    background-position: -380px 0px !important;
    height: 15px;
}

.jqx-editor-toolbar-icon:hover {
    opacity: 1;
}

.jqx-fill-state-disabled .jqx-editor-toolbar-icon {
    opacity: .667;
}

.jqx-editor-toolbar-item {
    display: inline-block;
    margin-left: 2px;
    vertical-align: middle;
}

.jqx-editor-toolbar-separator {
    border-left: 1px solid #ccc;
    margin: 0 2px;
    outline: none;
    overflow: hidden;
    padding: 0;
    text-decoration: none;
    vertical-align: middle;
    width: 0;
    display: inline-block;
}

.jqx-editor-toolbar-group {
    display: inline-block;
    margin-right: 4px;
    margin-top: 4px;
    white-space: nowrap;
}

.jqx-editor-toolbar-button {
    text-decoration: none;
    vertical-align: middle;
    cursor: default;
    display: inline-block;
    padding: 0px !important;
    margin-right: 2px;
}

.jqx-editor-toolbar-group .jqx-editor-toolbar-button {
    margin-right: -1px;
    box-shadow: none;
    position: relative;
    margin-left: 0px !important;
}

    .jqx-editor-toolbar-group .jqx-editor-toolbar-button:hover, .jqx-editor-toolbar-group .jqx-editor-toolbar-button:active {
        z-index: 55;
    }

    .jqx-editor-toolbar-group .jqx-editor-toolbar-button.jqx-fill-state-normal {
        background: transparent !important;
    }

.jqx-date-time-input-popup table td a {
    border: 1px transparent solid;
    width: 100%;
    display: inline-block;
    margin: 0;
    padding: 0;
    outline: 0;
    color: #333;
}

.jqx-date-time-input-popup table td input {
    width: 25px;
    margin: 0;
    text-align: center;
    padding: 4px 6px;
    height: 20px;
    line-height: 20px;
    vertical-align: middle;
}

.jqx-scheduler .jqx-cell {
    padding: 0px !important;
    cursor: default;
}

.jqx-scheduler .jqx-widget-header.jqx-grid-header {
    border-bottom-color: transparent;
    border-bottom-width: 0px;
}

.jqx-scheduler .jqx-grid-column-header, .jqx-scheduler.jqx-widget .jqx-grid-column-header {
    border-bottom: 1px solid #c5c5c5;
}

.jqx-grid-column-header[sort] > div > div {
    width: calc(100% - 20px);
}

.jqx-grid-column-header[filter] > div > div {
    width: calc(100% - 20px);
}

.jqx-grid-column-header[filter][sort] > div > div {
    width: calc(100% - 40px);
}

.jqx-scheduler-all-day-cell {
    text-align: left;
}

    .jqx-scheduler-all-day-cell span {
        margin-left: 3px;
    }

.jqx-scheduler-month-cell {
    background: #f2f2f2;
    color: #000;
    opacity: 0.8;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: inherit;
    vertical-align: top;
    text-align: left;
}

.jqx-scheduler-month-cell-touch {
    background: transparent;
    border: none;
    vertical-align: middle;
    text-align: center;
}

.jqx-scheduler-month-cell-popup {
    border: 1px solid #333;
    background: #f2f2f2;
    box-shadow: 0 5px 0px rgba(0,0,0,.15) !important;
}

.jqx-widget .jqx-scheduler-middle-cell, .jqx-scheduler-middle-cell {
    border-bottom-color: #ddd !important;
}

.jqx-scheduler-cell-selected.jqx-scheduler-middle-cell {
    border-bottom-style: dotted;
}

.jqx-scheduler-work-time-cell {
    background: #fff;
}

.jqx-scheduler-not-work-time-cell {
    background: #eee;
}

.jqx-scheduler-month-outer-cell {
    color: #555;
}

.jqx-scheduler-month-weekend-cell {
    background: #eee;
}

.jqx-scheduler-disabled-cell {
    background: #e9edf1;
}

.jqx-scheduler-toolbar {
    font-size: 14px;
}

.jqx-scheduler-time-column, .jqx-scheduler-toolbar {
    background-image: none !important;
    background: #fff !important;
    color: #333 !important;
}

.jqx-scheduler-week-number-column {
    background: #e8e8e8 !important;
    color: #333;
}

.jqx-scheduler-time-column-header-cell {
    border-left-color: transparent !important;
}

.jqx-scheduler-time-column-header-cell-rtl {
    border-right-color: transparent !important;
}

.jqx-scheduler-appointment {
    overflow: visible;
    border: 1px solid #555;
    white-space: pre-line;
    cursor: pointer;
    vertical-align: middle;
    text-overflow: ellipsis;
    user-select: none;
}

.jqx-scheduler-appointment-rtl {
    direction: rtl;
    text-align: right;
}

.jqx-scheduler-appointment-content {
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
    padding-left: 5px;
    padding-right: 5px;
}

.jqx-scheduler-appointment-inner-content {
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    padding-bottom: 3px;
}

.jqx-scheduler-appointment-duration-status {
    height: 0px;
    border-bottom-width: 2px;
    border-bottom-style: dotted;
    border-color: #f05c2d;
    background: #f05c2d;
    position: absolute;
    bottom: -1px;
    left: 0px;
    border-radius: 5px;
}

.jqx-scheduler-appointment-status {
    width: 4px;
    height: 100%;
    border-right-width: 1px;
    border-right-style: solid;
    position: absolute;
    top: 0px;
    left: 0px;
}

.jqx-scheduler-appointment-status-rtl {
    width: 4px;
    height: 100%;
    border-right-width: 0px;
    border-right-style: none;
    border-left-width: 1px;
    border-left-style: solid;
    position: absolute;
    top: 0px;
    right: 0px;
}

.jqx-scheduler-appointment-status-stripes {
    background-image: url('images/stripes.png');
    background-repeat: repeat-y;
    background-position: left top;
}

.jqx-scheduler-appointment-resize-indicator {
    width: 4px;
    height: 4px;
    position: absolute;
    border: 1px solid black;
    background: white;
    display: none;
}

.jqx-scheduler-appointment-top-resize-indicator {
    top: -3px;
    left: 50%;
    margin-left: -2px;
    cursor: row-resize;
}

.jqx-scheduler-appointment-bottom-resize-indicator {
    cursor: row-resize;
    bottom: -3px;
    left: 50%;
    margin-left: -2px;
}

.jqx-scheduler-appointment-left-resize-indicator {
    cursor: col-resize;
    top: 50%;
    left: -3px;
    margin-top: -2px;
}

.jqx-scheduler-appointment-right-resize-indicator {
    cursor: col-resize;
    top: 50%;
    margin-top: -2px;
    right: -3px;
}

.jqx-scheduler-appointment span {
    vertical-align: middle;
    text-overflow: ellipsis;
    position: relative;
    top: -1px;
}

.jqx-scheduler-feedback-appointment {
    opacity: 0.7;
    position: absolute;
    z-index: 99999;
}

.jqx-scheduler-selected-appointment {
    border: 1px solid #316293 !important;
    -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05) !important;
    -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05) !important;
    white-space: pre-line;
}

.jqx-scheduler-appointment:hover {
    border: 1px solid #3f7ebe !important;
}

.jqx-scheduler-feedback-drag-appointment {
    background: #aaa !important;
}

.jqx-icon-recurrence, .jqx-icon-recurrence-exception, .jqx-icon-recurrence-white, .jqx-icon-recurrence-exception-white, .jqx-icon-time {
    background-image: url('images/icon-recurrence.png');
    background-repeat: no-repeat;
    background-position: center;
    width: 16px;
    height: 16px;
    float: left;
    margin: 3px;
    margin-left: 5px;
}

.jqx-icon-time {
    background-image: url('images/icon-time.png');
    margin: 0px;
    position: relative;
    left: 1px;
    margin-top: -8px;
}

.jqx-icon-time-white {
    background-image: url('images/icon-time-white.png');
}

.jqx-icon-recurrence-exception {
    background-image: url('images/icon-recurrence-exception.png');
}

.jqx-icon-recurrence-exception-white {
    background-image: url('images/icon-recurrence-exception-white.png');
}

.jqx-icon-recurrence-white {
    background-image: url('images/icon-recurrence-white.png');
}

.jqx-scheduler-cell-hover {
    border-color: #3f7ebe !important;
    background: #a2c0df !important;
}

.jqx-scheduler-cell-selected {
    border-color: #316293 !important;
    background: #2f7eb6 !important;
}

.jqx-scheduler-edit-dialog-label {
    clear: both;
    float: left;
    margin-left: 2%;
    padding: 4px;
    text-align: right;
    width: 18%;
    line-height: 25px;
}

.jqx-scheduler-edit-dialog-field {
    clear: right;
    float: right;
    margin-right: 2%;
    padding: 4px;
    width: 380px;
}

.jqx-scheduler-edit-dialog-label-rtl {
    clear: both;
    float: right;
    margin-left: 0px;
    margin-right: 2%;
    padding: 4px;
    text-align: left;
    width: 18%;
    line-height: 25px;
}

.jqx-scheduler-edit-dialog-field-rtl {
    clear: left;
    float: left;
    margin-right: 0px;
    margin-left: 2%;
    padding: 4px;
    width: 72%;
}

.jqx-scheduler-legend-bar {
    overflow: hidden;
    border-color: inherit;
    border: none;
}

.jqx-scheduler-legend-bar-bottom {
    border-top-width: 1px;
    border-top-style: solid;
}

.jqx-scheduler-legend-bar-top {
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.jqx-scheduler-legend {
    width: 14px;
    height: 14px;
    float: left;
    margin-right: 3px;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    vertical-align: middle;
    margin-top: 4px;
}

.jqx-scheduler-legend-label {
    float: left;
    margin-right: 12px;
    cursor: pointer;
    vertical-align: middle;
    margin-top: 4px;
}

.jqx-scheduler-cell-selected .jqx-scheduler-agenda-date {
    color: #fff;
}

.jqx-scheduler-cell-selected .jqx-scheduler-agenda-time {
    color: #fff;
}

.jqx-scheduler-cell-selected .jqx-scheduler-agenda-appointment, .jqx-scheduler-cell-selected .jqx-scheduler-agenda-appointment-rtl {
    color: #fff;
}

.jqx-scheduler-agenda-date {
    padding-top: 5px;
    padding-bottom: 5px;
}

.jqx-scheduler-agenda-time {
    padding: 5px;
}

.jqx-scheduler-agenda-appointment, .jqx-scheduler-agenda-appointment-rtl {
    cursor: pointer;
}

.jqx-scheduler-agenda-appointment-resource, .jqx-scheduler-agenda-appointment-resource-rtl {
    margin: 5px;
    cursor: pointer;
}

.jqx-scheduler-agenda-appointment-resource-rtl, .jqx-scheduler-agenda-appointment-rtl {
    float: right;
}

.jqx-scheduler-agenda-day {
    margin: 3px;
    font-size: 33px;
    font-weight: normal;
}

.jqx-scheduler-agenda-week {
    font-style: normal;
    font-size: 13px;
}

.jqx-scheduler-agenda-date {
    font-size: 10px;
}

.jqx-scheduler-cell-focus {
    border-style: dotted;
    border-color: #59a0d5 !important;
    background: #59a0d5 !important;
}
/*applied to all widgets*/
.jqx-widget {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -ms-touch-action: none;
    zoom: 1 !important;
    color: #000000;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    -webkit-appearance: none;
    -webkit-background-clip: padding-box;
    direction: ltr !important;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-family: Verdana,Arial,sans-serif;
    font-style: normal;
    font-size: 13px;
}

.jqx-item {
    -ms-touch-action: none;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    color: inherit;
    -webkit-text-size-adjust: none;
}

/*applied to containers to set a background and border color. Tabs, NavigationBar, Calendar content.*/
.jqx-widget-content {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -ms-touch-action: none;
    -moz-background-clip: padding;
    -webkit-text-size-adjust: none;
    background-clip: padding-box;
    -webkit-background-clip: padding-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-family: Verdana,Arial,sans-serif;
    font-style: normal;
    font-size: 13px;
    border-color: #c7c7c7;
    background: #fff;
}
/*applied to header areas. Tabs, Menu, Window, Calendar.*/
.jqx-widget-header {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -ms-touch-action: none;
    -moz-background-clip: padding;
    -webkit-text-size-adjust: none;
    background-clip: padding-box;
    -webkit-background-clip: padding-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    border-color: #c5c5c5;
    background: #e8e8e8;
}

.jqx-fill-state-normal {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -ms-touch-action: none;
    font-family: Verdana,Arial,sans-serif;
    font-style: normal;
    font-size: 13px;
    border-color: #aaa;
    background: #efefef;
}

.jqx-fill-state-hover {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    border-color: #999;
    background: #e8e8e8;
}

.jqx-fill-state-pressed {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    border-color: #999;
    background: #d1d1d1;
}

.jqx-fill-state-focus {
    border-color: #555;
}

.jqx-fill-state-disabled {
    cursor: default;
    color: #000;
    opacity: .55;
    filter: Alpha(Opacity=45);
}
.jqx-grid.jqx-fill-state-disabled {
    pointer-events: none;
}
    .jqx-fill-state-normal td, .jqx-fill-state-hover td, .jqx-fill-state-pressed td, .jqx-fill-state-disabled td, .jqx-fill-state-focus td, .jqx-widget td, .jqx-widget-content td, .jqx-widget-header td {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
    }

.jqx-widget .jqx-grid-cell, .jqx-widget .jqx-grid-column-header, .jqx-widget .jqx-grid-group-cell {
    border-color: #aaa;
}

.jqx-widget .jqx-grid-column-menubutton, .jqx-widget .jqx-grid-column-sortascbutton, .jqx-widget .jqx-grid-column-sortdescbutton, .jqx-widget .jqx-grid-column-filterbutton {
    background-color: transparent;
    border-color: #aaa;
}

.jqx-grid-column-header a:link, .jqx-grid-column-header a:visited {
}

.jqx-grid-bottomright, .jqx-panel-bottomright, .jqx-listbox-bottomright {
    background-color: #e5e5e5;
}

.jqx-tabs-title-selected-top {
    border-color: #aaa;
    border-bottom: 1px solid #fff;
    background-color: #fff;
}
/*applied to the tab's title when the tab is selected and the jqxTab's position property is set to 'bottom' .*/
.jqx-tabs-title-selected-bottom {
    border-color: #aaa;
    border-top: 1px solid #fff;
    background-color: #fff;
}
/*applied to the tab's selection tracker when the jqxTab's position property is set to 'top'.*/
.jqx-tabs-selection-tracker-top {
    border-color: #aaa;
    border-bottom: 1px solid #fff;
}
/*applied to the tab's selection tracker when the jqxTab's position property is set to 'bottom'.*/
.jqx-tabs-selection-tracker-bottom {
    border-color: #aaa;
    border-top: 1px solid #fff;
}
/*jqxMaskedInput and jqxNumberInput Styles*/
.jqx-input {
    -webkit-appearance: none;
    padding: 0 0 0 0;
    outline: none;
    border-width: 1px;
    border-style: solid;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-background-clip: padding-box;
}

input[type=text].jqx-input, input[type=password].jqx-input {
    min-height: 16px;
    min-width: 16px;
    margin: 0px;
    border-width: 1px;
    border-style: solid;
    font-size: 13px;
    padding: 3px;
    padding-left: 3px;
    padding-right: 3px;
}

.jqx-input::-ms-reveal {
    display: none;
}

.jqx-input::-ms-clear {
    display: none;
}

.jqx-input-content::-ms-clear {
    display: none;
}

.jqx-input::-ms-value {
    margin: 0px;
    padding: 0px;
    min-height: 13px;
    line-height: 13px;
}

/*applied to the input when the validation fails.*/
.jqx-input-invalid {
    border-color: #dd4b39 !important;
}

/*applied to the input's content.*/
.jqx-input-content {
    -webkit-appearance: none;
    padding: 1px;
    resize: none;
    outline: none;
    outline-width: 0px;
    -webkit-user-modify: inherit;
    -moz-user-modify: inherit;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-background-clip: padding-box;
}
/*applied to the input when it is disabled.*/
.jqx-input-disabled {
    -webkit-appearance: none;
    resize: none;
    outline: none;
    outline-width: 0px;
}
/*applied to the list menu.*/
.jqx-listmenu-widget {
    -ms-touch-action: auto;
}

    .jqx-listmenu-widget .jqx-widget-content {
        -ms-touch-action: auto;
    }

    .jqx-listmenu-widget .jqx-widget-header {
        -ms-touch-action: auto;
    }

    .jqx-listmenu-widget li {
        -ms-touch-action: auto;
    }

.jqx-menu-popup {
}

.jqx-menu-vertical {
    background-color: #efefef;
}

.jqx-color-picker {
}

.jqx-color-picker-panel {
}

.jqx-color-picker-map {
    border: 1px solid #A0A0A0;
    background-color: transparent;
    background-image: url('images/colorpicker/map-saturation.png');
    background-repeat: no-repeat;
    background-position: left top;
    overflow: hidden;
}

.jqx-color-picker-map-hue {
    border: 1px solid #A0A0A0;
    background-color: transparent;
    background-image: url('images/colorpicker/map-hue.png');
    background-repeat: no-repeat;
    background-position: left top;
    overflow: hidden;
}

.jqx-color-picker-map-overlay {
    background-color: transparent;
    background-image: url('images/colorpicker/map-saturation-overlay.png');
    background-repeat: no-repeat;
    background-position: left top;
}

.jqx-color-picker-bar {
    border: 1px solid #A0A0A0;
    background-color: transparent;
    background-image: url('images/colorpicker/bar-saturation.png');
    background-repeat: no-repeat;
    background-position: left top;
}

.jqx-color-picker-bar-hue {
    border: 1px solid #A0A0A0;
    background-color: transparent;
    background-image: url('images/colorpicker/bar-hue.png');
    background-repeat: no-repeat;
    background-position: left top;
}

.jqx-color-picker-preview {
    border: 1px solid #A0A0A0;
}

.jqx-color-picker-pointer {
    background-image: url('images/crosshair.png');
    background-repeat: no-repeat;
    height: 16px;
    width: 16px;
}

.jqx-color-picker-pointer-alt {
    background-image: url('images/crosshair-white.png');
    background-repeat: no-repeat;
    height: 16px;
    width: 16px;
}

.jqx-color-picker-bar-pointer {
    background-image: url('images/pointer.png');
    background-repeat: no-repeat;
    height: 15px;
    margin-left: 6px;
    background-position: right top;
}

.jqx-icon {
    width: 16px;
    height: 16px;
    margin: 0 auto;
    position: relative;
    top: 50%;
    margin-top: -8px;
}

.jqx-input-icon {
    width: 13px;
    height: 13px;
    margin: 0 auto;
}

.jqx-scrollbar-thumb-state-normal-touch {
    border-color: #c3c3c3;
    background: #c3c3c3;
}

.jqx-touch, .jqx-touch .jqx-fill-state-normal, .jqx-touch .jqx-widget-content, .jqx-touch .jqx-widget-header, .jqx-touch a:link {
}

.jqx-scrollbar .jqx-icon-arrow-up {
    width: 100%;
    height: 100%;
}

.jqx-scrollbar .jqx-icon-arrow-down {
    width: 100%;
    height: 100%;
}

.jqx-scrollbar .jqx-icon-arrow-left {
    width: 100%;
    height: 100%;
}

.jqx-scrollbar .jqx-icon-arrow-right {
    width: 100%;
    height: 100%;
}
/*jqx-listmenu*/
.jqx-listmenu-widget {
    border-width: 1px;
    border-style: solid;
}

ol.jqx-listmenu, ul.jqx-listmenu {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    line-height: 1;
    counter-reset: section;
}

.jqx-listmenu li {
    margin-left: 0px;
    list-style: none;
    line-height: 1;
}

.jqx-listmenu {
    width: 100%;
}

.jqx-listmenu-header {
    padding: 15px;
}

.jqx-listmenu-item {
    cursor: pointer;
    padding: 15px;
    float: none;
    overflow: hidden;
    text-align: left;
    position: relative;
}

.jqx-listmenu-separator {
    padding: 10px;
    counter-reset: section;
}

.jqx-listmenu-header-label {
    margin-left: 30px;
}

.jqx-listmenu-arrow-right {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    margin-top: -7px;
    right: 7px;
}

.jqx-listmenu-arrow-rtl {
    right: 0px;
    left: 7px;
}

.jqx-listmenu-arrow-right-pressed {
    width: 16px;
    height: 16px;
}

.jqx-listmenu-header .jqx-button {
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    padding: 6px 10px;
}

.jqx-listmenu-filter-input {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    border: 1px solid;
    outline: none;
    width: 97%;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border-color: #c7c7c7;
    background-color: #fff;
    font-size: 20px;
    min-height: 25px !important;
    height: 25px;
}

.jqx-listmenu-filter {
    text-align: center;
    padding: 4px;
}

ol li .jqx-listmenu-item-label:before {
    content: counter(section) ". ";
    counter-increment: section;
}

.jqx-listmenu div {
    clear: both;
}

.jqx-listmenu-auto-separator {
}

.jqx-listmenu-item-readonly {
    cursor: default;
}

.jqx-tooltip {
    position: absolute;
    width: auto;
    height: auto;
}

.jqx-tooltip-main {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    filter: inherit;
    border-style: solid;
    border-width: 1px;
}

.jqx-tooltip-text {
    padding: 5px 8px;
    overflow: hidden;
    filter: inherit;
}

.jqx-tooltip, .jqx-tooltip-text, .jqx-tooltip-main {
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-background-clip: padding-box;
}

.jqx-tooltip-arrow {
    position: absolute;
    width: 0px;
    height: 0px;
    display: block;
    border-style: solid;
    margin: -6px 0 0 -7px;
    background: transparent !important;
    filter: inherit;
}

.jqx-tooltip-arrow-t-b {
    border-left-color: transparent !important;
    border-right-color: transparent !important;
}

.jqx-tooltip-arrow-l-r {
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
}
/*Sets the text-alignment to right.*/
.jqx-rtl {
    text-align: right !important;
}

.jqx-shadow {
    -webkit-box-shadow: 0 2px 3px rgba(0,0,0,.15) !important;
    -moz-box-shadow: 0 2px 3px rgba(0,0,0,.15) !important;
    box-shadow: 0 2px 3px rgba(0,0,0,.15) !important;
}

.jqx-noshadow {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.jqx-passwordinput-password-icon, .jqx-passwordinput-password-icon-rtl {
    background-image: url(./images/icon-showpassword.png) !important;
    background-repeat: no-repeat !important;
    width: 14px;
    height: 9px;
}

.jqx-passwordinput-password-icon-ltr {
    background-position: right !important;
}

.jqx-passwordinput-password-icon-rtl {
    background-position: left !important;
}

.jqx-passwordinput-password-strength-inicator {
    width: 20%;
    height: 5px;
    margin-top: 5px;
}
/*top rounded Corners*/
.jqx-rc-t-expanded {
    -moz-border-radius-topleft: 0px !important;
    -webkit-border-top-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    -moz-border-radius-topright: 0px !important;
    -webkit-border-top-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
/*bottom rounded Corners*/
.jqx-rc-b-expanded {
    -moz-border-radius-bottomleft: 0px !important;
    -webkit-border-bottom-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    -moz-border-radius-bottomright: 0px !important;
    -webkit-border-bottom-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.jqx-treemap-rectangle {
    font-size: 13px;
    font-weight: normal;
    border: 1px solid #555;
    color: #222;
    text-shadow: 0px 0px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.jqx-treemap-label {
    text-overflow: ellipsis;
    overflow: hidden;
}

.jqx-treemap-rectangle-parent {
    color: #222;
    font-size: 15px;
    font-family: Verdana;
    font-weight: bold;
}

.jqx-treemap-legend-label {
    color: #fff;
}

.jqx-treemap-legend {
    width: 350px;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 41;
    overflow: hidden;
}

.jqx-treemap-legend-table {
    table-layout: fixed;
    width: 100%;
}

.jqx-treemap-legend-color {
    height: 3px;
}

.jqx-treemap-legend-values {
    height: 12px;
    width: 100%;
}

.jqx-treemap-legend-value {
    font-size: 9px;
    position: absolute;
    color: #fff;
}

.jqx-treemap-rectangle-hover {
    -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    z-index: 40;
}
/*jqxRangeSelector*/
.jqx-rangeselector, .jqx-rangeselector-content, .jqx-rangeselector-slider, .jqx-rangeselector-markers,
.jqx-rangeselector-ticks-container, .jqx-rangeselector-ticks, .jqx-rangeselector-shutter {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-sizing: content-box !important;
}

.jqx-rangeselector-markers {
}

.jqx-rangeselector-content, .jqx-rangeselector-ticks, .jqx-rangeselector-labels, .jqx-rangeselector-shutter, .jqx-rangeselector-markers, .jqx-rangeselector-marker-arrow {
    position: absolute;
}

.jqx-rangeselector-ticks-container, .jqx-rangeselector-ticks, .jqx-rangeselector-shutter, .jqx-rangeselector-slider {
    height: 100%;
}

.jqx-rangeselector-ticks-container, .jqx-rangeselector-slider {
    width: 100%;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -moz-border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    border-radius: 0px !important;
}

.jqx-rangeselector-group-labels-rtl, .jqx-rangeselector-group-labels-ticks, .jqx-rangeselector-slider {
    float: left;
}

.jqx-rangeselector-slider, .jqx-rangeselector-markers, .jqx-rangeselector-marker-arrow {
    border-style: solid;
}

.jqx-rangeselector-ticks, .jqx-rangeselector-group-labels-ticks {
    width: 1px;
    background: #aaa;
}

.jqx-rangeselector-labels, .jqx-rangeselector-markers {
    white-space: nowrap;
    width: auto;
}

.jqx-rangeselector-slider, .jqx-rangeselector-markers {
    border-width: 1px !important;
}

.jqx-rangeselector-ticks-container {
    background-color: transparent;
    position: relative;
}

.jqx-rangeselector-shutter {
    opacity: 0.75;
    background: #efefef;
    border-width: 0px !important;
}

.jqx-rangeselector-slider {
    background-color: transparent !important;
    border-top: none !important;
    background-image: none !important;
    border-bottom: none !important;
    border-color: #aaa;
    border-right-color: transparent !important;
    position: relative;
    overflow: hidden;
}

.jqx-rangeselector-inner-slider {
    position: absolute;
    right: 0;
    background: #aaa;
    width: 1px;
    height: 100%;
}

.jqx-rangeselector-ticks {
    margin-top: 0px;
}

.jqx-rangeselector-labels, .jqx-rangeselector-group-labels {
    margin-top: 4px;
}

.jqx-rangeselector-markers {
    height: 20px;
    padding: 5px;
    opacity: 0.75;
}

.jqx-rangeselector-marker-arrow {
    width: 0px;
    height: 0px;
    display: block;
    margin: -6px 0 0 -7px;
    background-color: transparent !important;
    filter: inherit;
    border-top-width: 5px;
    border-bottom-width: 5px;
    border-right-color: transparent !important;
    border-left-color: transparent !important;
    background-clip: border-box;
    background-repeat: repeat;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    background-image: none !important;
}

.jqx-rangeselector-marker-arrow-top {
    border-bottom-color: transparent !important;
}

.jqx-rangeselector-marker-arrow-bottom {
    border-top-color: transparent !important;
}

.jqx-rangeselector-marker-left-arrow {
    border-right-width: 0px;
    border-left-width: 5px;
}

.jqx-rangeselector-marker-right-arrow {
    border-right-width: 5px;
    border-left-width: 0px;
}

.jqx-rangeselector-markers-value {
    overflow: hidden;
    white-space: nowrap;
}

.jqx-rangeselector-group-labels-rtl {
    margin-right: 10px;
    direction: rtl;
}

.jqx-rangeselector-group-labels-ticks {
    height: 17px;
}

.jqx-rangeselector-group-labels-ticks-rtl {
    margin-left: 5px;
}

.jqx-action-button {
    border: none;
    border-left: 1px solid #c7c7c7;
}

.jqx-action-button-rtl {
    border: none;
    border-right: 1px solid #c7c7c7;
    cursor: pointer;
}
/*jqxBulletChart*/
.jqx-bulletchart {
}

.jqx-bulletchart, .jqx-bulletchart-title-container-horizontal, .jqx-bulletchart-title-container-vertical, .jqx-bulletchart-chart-container-horizontal, .jqx-bulletchart-chart-container-vertical {
    position: relative;
}

.jqx-bulletchart-title-inner-container, .jqx-bulletchart-range, .jqx-bulletchart-pointer, .jqx-bulletchart-target, .jqx-bulletchart-ticks, .jqx-bulletchart-labels {
    position: absolute;
}

.jqx-bulletchart-title-container-vertical, .jqx-bulletchart-title, .jqx-bulletchart-description, .jqx-bulletchart-range-vertical {
    width: 100%;
}

.jqx-bulletchart-title-container-horizontal, .jqx-bulletchart-chart-container-horizontal {
    float: left;
    overflow: hidden;
}

.jqx-bulletchart-title, .jqx-bulletchart-description {
    text-align: center;
}

.jqx-bulletchart-title-inner-container {
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.jqx-bulletchart-title-inner-container-ltr {
    padding-right: 5px;
}

.jqx-bulletchart-title-inner-container-rtl {
    padding-left: 5px;
}

.jqx-bulletchart-title-description-ltr {
    text-align: right;
}

.jqx-bulletchart-title-description-rtl {
    text-align: left;
}

.jqx-bulletchart-title-description-vertical {
    text-align: center;
}

.jqx-bulletchart-title {
    font-size: 110%;
    font-weight: bold;
}

.jqx-bulletchart-description {
    font-size: 90%;
}

.jqx-bulletchart-range-horizontal {
    height: 100%;
}

.jqx-bulletchart-target-horizontal {
    top: 10%;
    height: 80%;
}

.jqx-bulletchart-target-vertical {
    left: 10%;
    width: 80%;
}

.jqx-bulletchart-ticks {
    background-color: #AAAAAA;
}

.jqx-bulletchart-ticks-horizontal {
    width: 1px;
}

.jqx-bulletchart-ticks-vertical {
    height: 1px;
}

.jqx-bulletchart-zero-tick {
}

.jqx-bulletchart-labels {
}
/*jqxNotification*/
.jqx-notification {
    padding: 10px;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    pointer-events: auto;
}

.jqx-notification-table {
    width: 100%;
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;
    color: inherit;
}

.jqx-notification-container {
    position: fixed;
    height: auto;
    width: auto;
    pointer-events: none;
}

.jqx-notification-container-full-width {
    width: 100%;
}

.jqx-notification-container-full-height {
    height: 100%;
}

#jqxNotificationDefaultContainer-top-right, #jqxNotificationDefaultContainer-bottom-left {
    direction: rtl;
}

.jqx-notification-icon, .jqx-notification-content {
    vertical-align: middle;
}

.jqx-notification-content {
}

.jqx-notification-content-rtl {
    direction: rtl;
}

.jqx-link, .jqx-inverse, .jqx-primary, .jqx-warning, .jqx-danger, .jqx-success, .jqx-info {
    background: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
}

    .jqx-primary .jqx-icon-calendar, .jqx-primary .jqx-icon-calendar-hover, .jqx-primary .jqx-icon-calendar-pressed {
        background-image: url('images/icon-calendar-white.png');
    }

    .jqx-primary .jqx-icon-time, .jqx-primary .jqx-icon-time-hover, .jqx-primary .jqx-icon-time-pressed {
        background-image: url('images/icon-time-white.png');
    }

    .jqx-success .jqx-icon-calendar, .jqx-success .jqx-icon-calendar-hover, .jqx-success .jqx-icon-calendar-pressed {
        background-image: url('images/icon-calendar-white.png');
    }

    .jqx-success .jqx-icon-time, .jqx-success .jqx-icon-time-hover, .jqx-success .jqx-icon-time-pressed {
        background-image: url('images/icon-time-white.png');
    }

    .jqx-warning .jqx-icon-calendar, .jqx-warning .jqx-icon-calendar-hover, .jqx-warning .jqx-icon-calendar-pressed {
        background-image: url('images/icon-calendar-white.png');
    }

    .jqx-warning .jqx-icon-time, .jqx-warning .jqx-icon-time-hover, .jqx-warning .jqx-icon-time-pressed {
        background-image: url('images/icon-time-white.png');
    }

    .jqx-danger .jqx-icon-calendar, .jqx-danger .jqx-icon-calendar-hover, .jqx-danger .jqx-icon-calendar-pressed {
        background-image: url('images/icon-calendar-white.png');
    }

    .jqx-danger .jqx-icon-time, .jqx-danger .jqx-icon-time-hover, .jqx-danger .jqx-icon-time-pressed {
        background-image: url('images/icon-time-white.png');
    }

    .jqx-info .jqx-icon-calendar, .jqx-info .jqx-icon-calendar-hover, .jqx-info .jqx-icon-calendar-pressed {
        background-image: url('images/icon-calendar-white.png');
    }

    .jqx-info .jqx-icon-time, .jqx-info .jqx-icon-time-hover, .jqx-info .jqx-icon-time-pressed {
        background-image: url('images/icon-time-white.png');
    }

    .jqx-inverse .jqx-icon-calendar, .jqx-inverse .jqx-icon-calendar-hover, .jqx-inverse .jqx-icon-calendar-pressed {
        background-image: url('images/icon-calendar-white.png');
    }

    .jqx-inverse .jqx-icon-time, .jqx-inverse .jqx-icon-time-hover, .jqx-inverse .jqx-icon-time-pressed {
        background-image: url('images/icon-time-white.png');
    }


.jqx-notification-info, .jqx-info {
    color: #ffffff !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25) !important;
    background-color: #5bc0de !important;
    *background-color: #5bc0de !important;
    border-left-color: #46b8da !important;
    border-right-color: #46b8da !important;
    border-top-color: #46b8da !important;
    border-bottom-color: #46b8da !important;
}

    .jqx-info:hover,
    .jqx-info:focus,
    .jqx-info:active,
    .jqx-info.active,
    .jqx-info.disabled,
    .jqx-info[disabled] {
        color: #ffffff !important;
        background-color: #31b0d5 !important;
        *background-color: #31b0d5 !important;
        border-color: #269abc !important;
    }

    .jqx-fill-state-pressed.jqx-info,
    .jqx-info:active,
    .jqx-info.active {
        background-color: #269abc \9 !important;
    }

.jqx-notification-warning, .jqx-warning {
    color: #ffffff !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25) !important;
    background-color: #f0ad4e !important;
    *background-color: #f0ad4e !important;
    border-left-color: #eea236 !important;
    border-right-color: #eea236 !important;
    border-top-color: #eea236 !important;
    border-bottom-color: #eea236 !important;
}

    .jqx-warning:hover,
    .jqx-warning:focus,
    .jqx-warning:active,
    .jqx-warning.active,
    .jqx-warning.disabled,
    .jqx-warning[disabled] {
        color: #ffffff !important;
        background-color: #ec971f !important;
        *background-color: #ec971f !important;
        border-color: #d58512 !important;
    }

    .jqx-fill-state-pressed.jqx-warning,
    .jqx-warning:active,
    .jqx-warning.active {
        background-color: #d58512 \9 !important;
        border-color: #985f0d !important;
    }

.jqx-notification-success, .jqx-success {
    color: #ffffff !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25) !important;
    background-color: #5cb85c !important;
    *background-color: #5cb85c !important;
    border-left-color: #4cae4c !important;
    border-right-color: #4cae4c !important;
    border-top-color: #4cae4c !important;
    border-bottom-color: #4cae4c !important;
}

    .jqx-success:hover,
    .jqx-success:focus,
    .jqx-success:active,
    .jqx-success.active,
    .jqx-success.disabled,
    .jqx-success[disabled] {
        color: #ffffff !important;
        background-color: #449d44 !important;
        *background-color: #449d44 !important;
        border-color: #398439 !important;
    }

    .jqx-fill-state-pressed.jqx-success,
    .jqx-success:active,
    .jqx-success.active {
        background-color: #398439 !important;
        border-color: #255625 !important;
    }

.jqx-notification-error, .jqx-danger {
    color: #ffffff !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25) !important;
    background-color: #d9534f !important;
    *background-color: #d9534f !important;
    border-left-color: #d43f3a !important;
    border-right-color: #d43f3a !important;
    border-top-color: #d43f3a !important;
    border-bottom-color: #d43f3a !important;
}

    .jqx-danger:hover,
    .jqx-danger:focus,
    .jqx-danger:active,
    .jqx-danger.active,
    .jqx-danger.disabled,
    .jqx-danger[disabled] {
        color: #ffffff !important;
        background-color: #c9302c !important;
        *background-color: #c9302c !important;
        border-color: #ac2925 !important;
    }

    .jqx-fill-state-pressed.jqx-danger, .jqx-danger:active,
    .jqx-danger.active {
        background-color: #ac2925 !important;
        border-color: #761c19 !important;
    }

.jqx-notification-mail, .jqx-primary {
    color: #ffffff !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25) !important;
    background-color: #337ab7 !important;
    *background-color: #337ab7 !important;
    border-left-color: #2e6da4 !important;
    border-right-color: #2e6da4 !important;
    border-top-color: #2e6da4 !important;
    border-bottom-color: #2e6da4 !important;
}

    .jqx-primary:hover,
    .jqx-primary:focus,
    .jqx-primary:active,
    .jqx-primary.active,
    .jqx-primary.disabled,
    .jqx-primary[disabled] {
        color: #ffffff !important;
        background-color: #286090 !important;
        *background-color: #286090 !important;
    }

    .jqx-fill-state-pressed.jqx-primary,
    .jqx-primary:active,
    .jqx-primary.active {
        background-color: #204d74 !important;
    }

.jqx-notification-time, .jqx-inverse {
    color: #ffffff !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25) !important;
    background-color: #363636 !important;
    *background-color: #222222 !important;
    border-left-color: #222222 !important;
    border-right-color: #222222 !important;
    border-top-color: #222222 !important;
    border-bottom-color: #000000 !important;
}

    .jqx-inverse:hover,
    .jqx-inverse:focus,
    .jqx-inverse:active,
    .jqx-inverse.active,
    .jqx-inverse.disabled,
    .jqx-inverse[disabled] {
        color: #ffffff !important;
        background-color: #222222 !important;
        *background-color: #151515 !important;
    }

    .jqx-fill-state-pressed.jqx-inverse,
    .jqx-inverse:active,
    .jqx-inverse.active {
        background-color: #080808 !important;
        background-image: linear-gradient(to bottom, #444444, #444444) !important;
    }

.jqx-link,
.jqx-link:active,
.jqx-link[disabled] {
    background-color: transparent !important;
    background-image: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.jqx-link {
    color: #0088cc !important;
    cursor: pointer !important;
    border-color: transparent !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

    .jqx-link:hover,
    .jqx-link:focus {
        color: #005580 !important;
        text-decoration: underline !important;
        background-color: transparent !important;
    }

    .jqx-link[disabled]:hover,
    .jqx-link[disabled]:focus {
        color: #333333 !important;
        text-decoration: none !important;
    }

    .jqx-link:focus, .jqx-inverse:focus, .jqx-primary:focus, .jqx-warning:focus, .jqx-danger:focus, .jqx-success:focus, .jqx-info:focus,
    .jqx-link:hover, .jqx-inverse:hover, .jqx-primary:hover, .jqx-warning:hover, .jqx-danger:hover, .jqx-success:hover, .jqx-info:hover {
        background-position: 0 -15px !important;
        -webkit-transition: background-position 0.1s linear !important;
        -moz-transition: background-position 0.1s linear !important;
        -o-transition: background-position 0.1s linear !important;
        transition: background-position 0.1s linear !important;
    }

    .jqx-link:active, .jqx-inverse:active, .jqx-primary:active, .jqx-warning:active, .jqx-danger:active, .jqx-success:active, .jqx-info:active {
        background-image: none !important;
        outline: 0 !important;
        -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05) !important;
        -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05) !important;
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05) !important;
    }

.jqx-notification-icon {
    background-repeat: no-repeat;
}

.jqx-notification-icon-ltr {
    background-position: left center;
}

.jqx-notification-icon-rtl {
    background-position: right center;
}

.jqx-notification-icon-info, .jqx-notification-icon-warning, .jqx-notification-icon-success, .jqx-notification-icon-error, .jqx-notification-icon-mail, .jqx-notification-icon-time {
    width: 30px;
    height: 25px;
}

.jqx-notification-icon-info {
    background-image: url('images/info.png');
}

.jqx-notification-icon-warning {
    background-image: url('images/warning.png');
}

.jqx-notification-icon-success {
    background-image: url('images/success.png');
}

.jqx-notification-icon-error {
    background-image: url('images/error.png');
}

.jqx-notification-icon-mail {
    background-image: url('images/mail.png');
}

.jqx-notification-icon-time {
    background-image: url('images/time.png');
}

.jqx-notification-close-button-info, .jqx-notification-close-button-success, .jqx-notification-close-button-mail, .jqx-notification-close-button-time, .jqx-notification-close-button-error {
    background-image: url('images/close_white.png');
}

.jqx-notification-close-button-warning {
    background-image: url('images/close_black.png');
}

.jqx-notification-close-button-container, .jqx-notification-close-button {
    width: 16px;
    height: 16px;
}

.jqx-notification-close-button-container {
    vertical-align: top;
}

.jqx-notification-close-button {
    position: relative;
    top: -11px;
    background-repeat: no-repeat;
    cursor: pointer;
}

.jqx-notification-close-button-ltr {
    right: -11px;
    background-position: right top;
}

.jqx-notification-close-button-rtl {
    left: -11px;
    background-position: left top;
}

.jqx-navbar {
    width: 100%;
    border-style: solid;
    border-width: 1px;
}

    .jqx-navbar ul:before,
    .jqx-navbar ul:after {
        content: "";
        display: table;
    }

    .jqx-navbar ul:after {
        clear: both;
    }

    .jqx-navbar ul {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        display: block;
        border: 0;
        max-width: 100%;
        overflow: visible;
    }

.jqx-navbar-block {
    margin: 0;
    overflow: hidden;
    padding: 0px 12px;
    border-width: 0px;
    float: left;
    min-height: 1px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    box-shadow: none !important;
    border-radius: 0px !important;
}

.jqx-navbar-block-rtl {
    float: right;
}

.jqx-ribbon {
    position: relative;
    overflow: hidden;
}

.jqx-ribbon-auto {
    display: inline-block;
}

.jqx-ribbon-popup {
    overflow: visible;
}

.jqx-ribbon-header {
    position: absolute;
    margin: 0;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    overflow: hidden;
    float: left;
    display: block;
    box-shadow: none !important;
}

.jqx-ribbon-header-auto {
    position: relative;
}

.jqx-ribbon-header-horizontal {
    width: 100%;
    min-height: 30px;
    padding: 0px;
    white-space: nowrap;
}

.jqx-ribbon-header-bottom {
    bottom: 0;
}

.jqx-ribbon-header-vertical {
    min-width: 75px;
    height: 100%;
    float: left;
    padding: 0px;
}

.jqx-ribbon-header-right {
    right: 0;
}

.jqx-ribbon-header-horizontal-popup, .jqx-ribbon-header-vertical-popup {
    position: relative;
}

.jqx-ribbon-header-horizontal-popup {
    height: 100%;
}

.jqx-ribbon-header-vertical-popup {
    width: 100%;
}

.jqx-ribbon-item {
    position: relative;
    list-style-type: none;
    padding: 5px;
    border: 1px solid transparent;
    box-sizing: border-box;
    text-overflow: ellipsis;
    cursor: default;
    min-width: 10px;
    vertical-align: top;
}

.jqx-ribbon-item-top, .jqx-ribbon-item-bottom {
    height: 100%;
    display: inline-block;
    margin-left: 1px;
    margin-right: 1px;
}

.jqx-ribbon-item-top {
    margin-top: 1px;
}

.jqx-ribbon-item-bottom {
    margin-top: -1px;
}

.jqx-ribbon-item-left, .jqx-ribbon-item-right {
    width: 100%;
    margin-top: 2px;
}

.jqx-ribbon-item-left {
    margin-left: 1px;
}

.jqx-ribbon-item-right {
    margin-left: -1px;
}

.jqx-ribbon-item-hover {
    border-color: inherit;
}

.jqx-ribbon-item-top.jqx-ribbon-item-hover {
    border-bottom-color: inherit !important;
}

.jqx-ribbon-item-bottom.jqx-ribbon-item-hover {
    border-top-color: inherit !important;
}

.jqx-ribbon-item-left.jqx-ribbon-item-hover {
    border-right-color: inherit !important;
}

.jqx-ribbon-item-right.jqx-ribbon-item-hover {
    border-left-color: inherit !important;
}

.jqx-ribbon-item-selected {
    border-color: inherit;
}

.jqx-ribbon-item-top.jqx-ribbon-item-selected {
    border-bottom-color: transparent;
}

.jqx-ribbon-item-bottom.jqx-ribbon-item-selected {
    border-top-color: transparent;
}

.jqx-ribbon-item-left.jqx-ribbon-item-selected {
    border-right-color: transparent;
}

.jqx-ribbon-item-right.jqx-ribbon-item-selected {
    border-left-color: transparent;
}

.jqx-ribbon-content {
    background-image: none !important;
    background-color: transparent !important;
    box-sizing: border-box;
}

.jqx-ribbon-content-horizontal {
    clear: both;
    height: 100%;
}

.jqx-ribbon-content-vertical {
    height: 100%;
    width: 100%;
    float: left;
    border-width: 1px;
}

.jqx-ribbon-content-popup {
    z-index: 99999;
    position: absolute;
    border-left: none;
}

    .jqx-ribbon-content-popup.jqx-ribbon-content-horizontal {
        width: 100%;
        height: auto;
    }

    .jqx-ribbon-content-popup.jqx-ribbon-content-vertical {
        width: auto;
        height: 100%;
    }

.jqx-ribbon-content-popup-top {
    -webkit-box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.jqx-ribbon-content-popup-bottom {
    -webkit-box-shadow: 0 -6px 15px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 -6px 15px rgba(0, 0, 0, 0.15);
    box-shadow: 0 -6px 15px rgba(0, 0, 0, 0.15);
}

.jqx-ribbon-content-popup-left {
    -webkit-box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.jqx-ribbon-content-popup-right {
    -webkit-box-shadow: -6px 6px 15px 0 rgba(0,0,0,0.15);
    box-shadow: -6px 6px 15px 0 rgba(0,0,0,0.15);
}

.jqx-ribbon-content-auto-width {
    width: auto;
}

.jqx-ribbon-content-section {
    width: 100%;
    height: 100%;
    display: none;
    border-width: 1px;
    border-style: solid;
    box-sizing: inherit;
    overflow: auto;
}

.jqx-ribbon-content-section-top {
    border-top: none;
}

.jqx-ribbon-content-section-bottom {
    bottom: 0;
    border-bottom: none;
}

.jqx-ribbon-content-section-left {
    border-left: none;
}

.jqx-ribbon-content-section-right {
    border-right: none;
    right: 0;
}

.jqx-ribbon-content-section-popup {
    position: relative;
}

.jqx-ribbon-content-section-horizontal-popup {
    height: auto;
}

.jqx-ribbon-content-section-vertical-popup {
    width: auto;
}

.jqx-ribbon-header-rtl {
    text-align: right;
}

.jqx-ribbon-header-horizontal.jqx-ribbon-header-rtl {
    direction: rtl;
}

.jqx-ribbon-item-rtl {
    direction: rtl;
}

.jqx-ribbon-scrollbutton {
    position: absolute;
    display: none;
    box-sizing: border-box;
    cursor: pointer;
    background-repeat: no-repeat !important;
    background-position: center !important;
    z-index: 0;
}

.jqx-ribbon-scrollbutton-top, .jqx-ribbon-scrollbutton-bottom {
    width: 17px;
}

.jqx-ribbon-scrollbutton-left, .jqx-ribbon-scrollbutton-right {
    height: 17px;
}

.jqx-ribbon-scrollbutton-top {
    top: 1px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.jqx-ribbon-scrollbutton-bottom {
    bottom: 1px;
    border-top-width: 1px;
    border-top-style: solid;
}

.jqx-ribbon-scrollbutton-left {
    left: 1px;
    border-right-width: 1px;
    border-right-style: solid;
}

.jqx-ribbon-scrollbutton-right {
    right: 1px;
    border-left-width: 1px;
    border-left-style: solid;
}

.jqx-ribbon-scrollbutton-top.jqx-ribbon-scrollbutton-lt.jqx-ribbon-scrollbutton-both, .jqx-ribbon-scrollbutton-top.jqx-ribbon-scrollbutton-lt.jqx-ribbon-scrollbutton-near, .jqx-ribbon-scrollbutton-bottom.jqx-ribbon-scrollbutton-lt.jqx-ribbon-scrollbutton-both, .jqx-ribbon-scrollbutton-bottom.jqx-ribbon-scrollbutton-lt.jqx-ribbon-scrollbutton-near {
    left: 1px;
    border-right-width: 1px;
    border-right-style: solid;
}

.jqx-ribbon-scrollbutton-top.jqx-ribbon-scrollbutton-lt.jqx-ribbon-scrollbutton-far, .jqx-ribbon-scrollbutton-bottom.jqx-ribbon-scrollbutton-lt.jqx-ribbon-scrollbutton-far {
    right: 18px;
    border-left-width: 1px;
    border-left-style: solid;
}

.jqx-ribbon-scrollbutton-top.jqx-ribbon-scrollbutton-rb.jqx-ribbon-scrollbutton-both, .jqx-ribbon-scrollbutton-top.jqx-ribbon-scrollbutton-rb.jqx-ribbon-scrollbutton-far, .jqx-ribbon-scrollbutton-bottom.jqx-ribbon-scrollbutton-rb.jqx-ribbon-scrollbutton-both, .jqx-ribbon-scrollbutton-bottom.jqx-ribbon-scrollbutton-rb.jqx-ribbon-scrollbutton-far {
    right: 1px;
    border-left-width: 1px;
    border-left-style: solid;
}

.jqx-ribbon-scrollbutton-top.jqx-ribbon-scrollbutton-rb.jqx-ribbon-scrollbutton-near, .jqx-ribbon-scrollbutton-bottom.jqx-ribbon-scrollbutton-rb.jqx-ribbon-scrollbutton-near {
    left: 18px;
    border-right-width: 1px;
    border-right-style: solid;
}

.jqx-ribbon-scrollbutton-left.jqx-ribbon-scrollbutton-lt.jqx-ribbon-scrollbutton-both, .jqx-ribbon-scrollbutton-left.jqx-ribbon-scrollbutton-lt.jqx-ribbon-scrollbutton-near, .jqx-ribbon-scrollbutton-right.jqx-ribbon-scrollbutton-lt.jqx-ribbon-scrollbutton-both, .jqx-ribbon-scrollbutton-right.jqx-ribbon-scrollbutton-lt.jqx-ribbon-scrollbutton-near {
    top: 1px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.jqx-ribbon-scrollbutton-left.jqx-ribbon-scrollbutton-lt.jqx-ribbon-scrollbutton-far, .jqx-ribbon-scrollbutton-right.jqx-ribbon-scrollbutton-lt.jqx-ribbon-scrollbutton-far {
    bottom: 18px;
    border-top-width: 1px;
    border-top-style: solid;
}

.jqx-ribbon-scrollbutton-left.jqx-ribbon-scrollbutton-rb.jqx-ribbon-scrollbutton-both, .jqx-ribbon-scrollbutton-left.jqx-ribbon-scrollbutton-rb.jqx-ribbon-scrollbutton-far, .jqx-ribbon-scrollbutton-right.jqx-ribbon-scrollbutton-rb.jqx-ribbon-scrollbutton-both, .jqx-ribbon-scrollbutton-right.jqx-ribbon-scrollbutton-rb.jqx-ribbon-scrollbutton-far {
    bottom: 1px;
    border-top-width: 1px;
    border-top-style: solid;
}

.jqx-ribbon-scrollbutton-left.jqx-ribbon-scrollbutton-rb.jqx-ribbon-scrollbutton-near, .jqx-ribbon-scrollbutton-right.jqx-ribbon-scrollbutton-rb.jqx-ribbon-scrollbutton-near {
    top: 18px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.jqx-ribbon-scrollbutton-inner {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-ribbon-selection-token {
    position: absolute;
    z-index: 0;
}

.jqx-ribbon-selection-token-top, .jqx-ribbon-selection-token-bottom {
    height: 1px;
}

.jqx-ribbon-selection-token-left, .jqx-ribbon-selection-token-right {
    width: 1px;
}

.jqx-ribbon-auto, .jqx-ribbon-item-top, .jqx-ribbon-item-bottom, .jqx-ribbon-content {
    *display: inline;
}

.jqx-ribbon-content-left {
    *float: none;
}

.jqx-ribbon-content-right {
    *float: left;
}

.jqx-ribbon-content.jqx-ribbon-content-popup {
    *left: 0;
}

.jqx-ribbon-content-popup .jqx-ribbon-content-section {
    *overflow: visible;
}

.jqx-ribbon-content-section-popup {
    *position: absolute;
}

.jqx-toolbar, .jqx-toolbar-minimized-popup-separator {
    box-sizing: border-box;
}

.jqx-toolbar, .jqx-toolbar-minimized-popup {
    padding: 5px;
}

.jqx-toolbar {
    position: relative;
    border-width: 1px;
    border-style: solid;
    overflow: hidden;
}

.jqx-toolbar-tool {
    float: left;
    margin-top: 0;
    margin-bottom: 0;
}

.jqx-toolbar-tool-rtl {
    float: right;
}

.jqx-toolbar-tool-no-separator-ltr {
    margin-right: 2px !important;
}

.jqx-toolbar-tool-no-separator-rtl {
    margin-left: 2px !important;
}

.jqx-toolbar-tool-separator-ltr {
    margin-right: 6px !important;
}

.jqx-toolbar-tool-separator-rtl {
    margin-left: 6px !important;
}

.jqx-toolbar-tool-inner-button {
    margin-right: 0;
    margin-left: 0;
    border-radius: 0 !important;
}

.jqx-toolbar-minimized-button, .jqx-toolbar-minimized-popup {
    position: absolute;
}

.jqx-toolbar-minimized-button {
    height: 100%;
    right: 0;
    cursor: pointer;
    margin-top: -5px;
    margin-right: 5px;
    display: none;
}

.jqx-toolbar-minimized-button-rtl {
    left: 0;
    margin-left: 5px;
    margin-right: 0;
}

.jqx-toolbar-minimized-popup {
    border-width: 1px;
    border-top-width: 0;
    border-style: solid;
    visibility: hidden;
    z-index: 1000;
}

.jqx-toolbar-tool-minimized {
    margin: 1px 0 !important;
}

.jqx-toolbar-minimized-popup-separator {
    width: 100%;
    height: 2px;
    margin: 5px 0;
    display: none;
}

.jqx-complex-input-spin-buttons-container {
    border-width: 1px;
    border-style: solid;
    position: relative;
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    box-sizing: border-box !important;
    padding: 2px 8px !important;
    white-space: nowrap;
    vertical-align: middle;
}

.jqx-formatted-input, .jqx-complex-input {
    padding-right: 2px !important;
    text-align: right;
}

.jqx-formatted-input-lower-case, .jqx-complex-input {
    text-transform: lowercase;
}

.jqx-formatted-input-upper-case {
    text-transform: uppercase;
}

.jqx-formatted-input-item {
    word-wrap: break-word;
}

.jqx-formatted-input-item-rtl, .jqx-complex-input-rtl {
    direction: rtl !important;
}

.jqx-formatted-input-spin-buttons-container, .jqx-formatted-input-addon {
    height: 100%;
}

.jqx-formatted-input-spin-buttons-container {
    width: 18px;
    padding: 0 0 0 1px !important;
    overflow: hidden;
}

.jqx-formatted-input-spin-buttons-container-rtl {
    border-right-width: 0px;
    padding: 0 0 0 2px !important;
}

.jqx-formatted-input-spin-buttons-container-rtl-border {
    border-left-width: 1px !important;
}

.jqx-formatted-input-spin-button {
    position: relative;
    width: 17px;
    height: 50%;
    margin-left: -1px;
    border-width: 0px;
    padding: 0px;
    overflow: hidden;
    cursor: pointer;
}

.jqx-formatted-input-addon {
    width: 19px;
    padding: 0 !important;
    cursor: pointer;
}

.jqx-formatted-input-addon-rtl {
    border-right-width: 1px !important;
}

.jqx-complex-input-parent {
    overflow: hidden;
}

.jqx-complex-input-child {
    height: 100%;
    float: left;
    box-sizing: border-box;
}

.jqx-complex-input-child-rtl {
    float: right;
}

.jqx-complex-input-spin-buttons-container-ltr {
    border-left-width: 0;
}

.jqx-complex-input-spin-buttons-container-rtl {
    border-left-width: 1px !important;
    border-right-width: 0;
}

.jqx-file-upload {
    position: relative;
    overflow: auto;
}

.jqx-file-upload, .jqx-file-upload-file-row, .jqx-file-upload-file-name, .jqx-file-upload-file-cancel, .jqx-file-upload-file-upload {
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
}

.jqx-file-upload-buttons-container, .jqx-file-upload-iframe, .jqx-file-upload-loading-element {
    display: none;
}

.jqx-file-upload-file-input-ie9, .jqx-file-upload-buttons-container, .jqx-file-upload-file-row, .jqx-file-upload-icon {
    width: 100%;
}

.jqx-file-upload-file-input-ie9, .jqx-file-upload-file-name, .jqx-file-upload-file-cancel, .jqx-file-upload-file-upload, .jqx-file-upload-icon, .jqx-file-upload-loading-element {
    height: 100%;
}

.jqx-file-input-iframe, .jqx-file-upload-form-ie9 {
    position: absolute;
}

.jqx-file-input-iframe {
    width: 0;
    height: 0;
    border: none;
}

.jqx-file-upload-form, .jqx-file-upload-file-input {
    visibility: hidden;
    display: none;
}

.jqx-file-upload-form-ie9, .jqx-file-upload-file-input-ie9 {
    display: block;
    visibility: visible;
}

.jqx-file-upload-form-ie9 {
    z-index: 10000;
    opacity: 0;
    filter: alpha(opacity=0);
}

.jqx-file-upload-file-name {
    width: auto;
    padding: 0 2px 0 2px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    *float: left;
}

.jqx-file-upload-button-browse-rtl, .jqx-file-upload-button-upload-rtl, .jqx-file-upload-button-cancel-rtl, .jqx-file-upload-file-name-rtl, .jqx-file-upload-file-cancel, .jqx-file-upload-file-upload, .jqx-file-upload-loading-element {
    float: right;
}

.jqx-file-upload, .jqx-file-upload-file-row {
    padding: 5px;
}

.jqx-file-upload-button-browse {
    display: block;
}

.jqx-file-upload-buttons-container {
    height: auto;
    margin-top: 10px;
}

.jqx-file-upload-button-browse-rtl, .jqx-file-upload-button-upload-rtl, .jqx-file-upload-button-cancel-rtl, .jqx-file-upload-file-name-rtl, .jqx-file-upload-file-cancel-rtl, .jqx-file-upload-file-upload-rtl {
    direction: rtl !important;
}

.jqx-file-upload-button-cancel, .jqx-file-upload-button-upload-rtl {
    margin-left: 5px;
}

.jqx-file-upload-button-upload-rtl-ie {
    margin-right: 5px;
}

.jqx-file-upload-button-cancel-rtl, .jqx-file-upload-button-upload-rtl-ie {
    margin-left: 0;
}

.jqx-file-upload-file-row {
    height: 30px;
    margin-bottom: 3px;
    clear: both;
}

.jqx-file-upload-file-cancel, .jqx-file-upload-file-upload, .jqx-file-upload-loading-element {
    width: 17px;
    margin: 0 1px 0 1px;
}

.jqx-file-upload-file-cancel-rtl, .jqx-file-upload-file-upload-rtl, .jqx-file-upload-loading-element-rtl {
    float: none;
    *float: left;
}

.jqx-file-upload-button-browse, .jqx-file-upload-button-upload, .jqx-file-upload-button-cancel, .jqx-file-upload-form-ie9, .jqx-file-upload-file-input-ie9, .jqx-file-upload-file-cancel, .jqx-file-upload-file-upload {
    cursor: pointer;
    white-space: nowrap;
}

.jqx-file-upload-file-name, .jqx-file-upload-file-cancel, .jqx-file-upload-file-upload {
    display: inline-block;
}

.jqx-file-upload-icon-upload, .jqx-file-upload-loading-element {
    margin-top: 1px;
}

.jqx-file-upload-loading-element {
    background-image: url('images/loader-small.gif');
    background-repeat: no-repeat;
}

.jqx-date-time-input-popup .jqx-icon {
    margin-top: 0px;
}


.jqx-text-area-element {
    height: 100%;
    width: 100%;
    overflow: hidden;
    border: none;
    outline: none;
    left: 0px;
    top: 0px;
    margin: 0px;
    position: absolute;
    resize: none;
}

.jqx-text-area-element-rtl {
    direction: rtl !important;
}

.jqx-responsive-panel {
    overflow: auto;
}

.jqx-responsive-panel {
    border-width: 0px;
}

.jqx-responsive-panel-button {
    border-width: 1px;
    border-style: solid;
}

.jqx-responsive-panel-button {
    display: none;
    cursor: pointer;
}

.jqx-responsive-panel-button-inner {
    margin: 0;
    width: 100%;
    height: 100%;
    background-position: center;
}

.jqx-loader {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255,255,255,0.5);
    z-index: 999;
    border-style: solid;
    border-width: 1px;
}

.jqx-loader-ie-transparency {
    background: transparent;
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#7fffffff, endColorstr=#7fffffff)"; /*For IE 8*/
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#7fffffff, endColorstr=#7fffffff); /*For IE 7*/
}

.jqx-loader-modal {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 99;
    cursor: default;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0.6;
}

.jqx-loader-icon {
    background-image: url("images/loader.gif");
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 999;
}

.jqx-loader-text {
    z-index: 999;
    position: relative;
}

.jqx-loader-text-left {
    margin-left: 5px;
    position: absolute;
    top: 50%;
}

.jqx-loader-text-right {
    margin-right: 5px;
    position: absolute;
    top: 50%;
    right: 0px;
}

.jqx-loader-text-top {
    text-align: center;
    margin-top: 5px;
}

.jqx-loader-text-bottom {
    bottom: 0px;
    position: absolute;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 5px;
}

.jqx-loader-rtl {
    direction: rtl;
}
/* jqxLayout and jqxDockingLayout */
.jqx-layout, .jqx-layout-group-root, .jqx-layout-pseudo-window-header, .jqx-docking-layout-overlay-inner-square-content {
    position: relative;
}

.jqx-layout-pseudo-window-pin-background, .jqx-layout-pseudo-window-close-background, .jqx-layout-resize-feedback, .jqx-layout-overlay, .jqx-docking-layout-overlay, .jqx-docking-layout-overlay-highlight-right, .jqx-docking-layout-overlay-highlight-bottom, .jqx-docking-layout-overlay-square-edge, .jqx-docking-layout-drop-overlay {
    position: absolute;
}

.jqx-layout {
    background-color: #c5c5c5;
}

.jqx-layout-group-root, .jqx-layout-pseudo-window-pin-icon, .jqx-layout-pseudo-window-pinned-icon, .jqx-layout-pseudo-window-close-icon, .jqx-layout-overlay {
    width: 100%;
    height: 100%;
}

.jqx-layout-group-default-horizontal > div, .jqx-layout-pseudo-window-title-ltr, .jqx-docking-layout-overlay-square, .jqx-docking-layout-overlay-mini-window-edge-horizontal, .jqx-docking-layout-overlay-square-edge-arrow-container-horizontal {
    float: left;
}

.jqx-layout-group-tabbed {
    position: static;
    box-sizing: border-box;
    min-height: 0 !important;
}

    .jqx-layout-group-tabbed .jqx-window-content, .jqx-docking-layout-group-floating .jqx-window-content {
        padding: 0;
    }

    .jqx-layout-group-tabbed .jqx-ribbon-content-section, .jqx-layout-window, .jqx-docking-layout-group-floating .jqx-ribbon-content-section {
        border: none;
    }

.jqx-layout-window {
    position: static;
}

.jqx-layout-group-auto-hide-content-vertical, .jqx-layout-group-auto-hide-content-horizontal {
    overflow: auto;
}

.jqx-layout-group-auto-hide-content-vertical {
    width: 150px;
}

.jqx-layout-group-auto-hide-content-horizontal {
    width: 100%;
    height: 150px;
}

.jqx-layout-pseudo-window {
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    box-shadow: none !important;
}

.jqx-layout-pseudo-window-header, .jqx-layout-pseudo-window-content {
    width: 100%;
    box-sizing: border-box;
}

.jqx-layout-pseudo-window-header {
    height: 30px;
}

.jqx-layout-pseudo-window-pin-background, .jqx-layout-pseudo-window-close-background {
    width: 16px;
    height: 16px;
    top: 5px;
}

.jqx-layout-pseudo-window-pin-background-ltr {
    right: 15px;
}

.jqx-layout-pseudo-window-close-background-ltr {
    right: 0;
}

.jqx-layout-pseudo-window-pin-background-rtl {
    left: 15px;
}

.jqx-layout-pseudo-window-close-background-rtl {
    left: 0;
}

.jqx-layout-pseudo-window-pin-icon, .jqx-layout-pseudo-window-pinned-icon, .jqx-layout-pseudo-window-close-icon {
    cursor: pointer;
}

.jqx-layout-pseudo-window-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.jqx-layout-pseudo-window-pin-icon {
    background-image: url("images/pin.png");
}

.jqx-layout-pseudo-window-pinned-icon {
    background-image: url("images/pinned.png");
}

.jqx-layout-pseudo-window-title-rtl {
    float: right;
}

.jqx-layout-resize-feedback {
    z-index: 9999;
    top: 0;
    left: 0;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    opacity: 0.6;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
    display: none;
}

.jqx-layout-resize-feedback-horizontal {
    width: 100%;
    height: 4px;
    cursor: row-resize;
}

.jqx-layout-resize-feedback-vertical {
    width: 4px;
    height: 100%;
    cursor: col-resize;
}

.jqx-layout-resize-feedback-warning {
    border-color: #800000 !important;
    background-color: Red !important;
}

.jqx-layout-overlay {
    top: 0;
    left: 0;
    display: none;
    background-color: transparent;
    z-index: 8999;
    pointer-events: none;
}

.jqx-layout-ribbon-header-ltr {
    padding-left: 20px;
}

.jqx-layout-ribbon-header-rtl {
    padding-right: 20px;
}

/* jqxDockingLayout-specific */
.jqx-docking-layout-group-floating {
    border-color: #FFD280 !important;
}

    .jqx-docking-layout-group-floating .jqx-window-header {
        border-color: #FFD280;
        background-color: #FFD280 !important;
        background: -webkit-linear-gradient(#FFE4B5, #FFD280) !important;
        background: -o-linear-gradient(#FFE4B5, #FFD280) !important;
        background: -moz-linear-gradient(#FFE4B5, #FFD280) !important;
        background: linear-gradient(#FFE4B5, #FFD280 ) !important;
    }

.jqx-docking-layout-overlay, .jqx-docking-layout-overlay-square-edge {
    z-index: 99999;
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
}

.jqx-docking-layout-overlay-square-edge {
    display: none;
}

.jqx-docking-layout-overlay {
    width: 100px;
    height: 100px;
    left: 100px;
    top: 70px;
}

.jqx-docking-layout-overlay-section {
    height: 33px;
    width: 100px;
}

.jqx-docking-layout-overlay-square {
    width: 26px;
    height: 26px;
    padding: 3px;
}

.jqx-docking-layout-overlay-square-invisible {
    background-color: transparent !important;
    background-image: none !important;
}

.jqx-docking-layout-overlay-square-top, .jqx-docking-layout-overlay-square-left, .jqx-docking-layout-overlay-square-center, .jqx-docking-layout-overlay-square-right, .jqx-docking-layout-overlay-highlight-bottom {
    border-top-width: 1px;
    border-top-style: solid;
}

.jqx-docking-layout-overlay-square-top, .jqx-docking-layout-overlay-square-left, .jqx-docking-layout-overlay-square-bottom, .jqx-docking-layout-overlay-highlight-right {
    border-left-width: 1px;
    border-left-style: solid;
}

.jqx-docking-layout-overlay-square-top, .jqx-docking-layout-overlay-square-right, .jqx-docking-layout-overlay-square-bottom, .jqx-docking-layout-overlay-highlight-left {
    border-right-width: 1px;
    border-right-style: solid;
}

.jqx-docking-layout-overlay-square-left, .jqx-docking-layout-overlay-square-center, .jqx-docking-layout-overlay-square-right, .jqx-docking-layout-overlay-square-bottom, .jqx-docking-layout-overlay-inner-square-header, .jqx-docking-layout-overlay-highlight-top {
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.jqx-docking-layout-overlay-square-center {
    border-color: transparent !important;
}

.jqx-docking-layout-overlay-inner-square, .jqx-docking-layout-overlay-inner-square-header, .jqx-docking-layout-overlay-inner-square-content, .jqx-docking-layout-overlay-highlight-top, .jqx-docking-layout-overlay-highlight-bottom, .jqx-docking-layout-overlay-mini-window-edge-vertical {
    width: 24px;
}

.jqx-docking-layout-overlay-inner-square {
    border-width: 1px;
    border-style: solid;
    height: 24px;
}

.jqx-docking-layout-overlay-inner-square-header {
    height: 5px;
}

.jqx-docking-layout-overlay-inner-square-content {
    height: 18px;
}

.jqx-docking-layout-overlay-highlight {
}

.jqx-docking-layout-overlay-highlight-top {
    height: 9px;
    border-bottom-style: dashed;
}

.jqx-docking-layout-overlay-highlight-left, .jqx-docking-layout-overlay-highlight-right {
    width: 12px;
    height: 18px;
}

.jqx-docking-layout-overlay-highlight-left {
    border-right-style: dashed;
}

.jqx-docking-layout-overlay-highlight-right {
    right: 0;
    border-left-style: dashed;
}

.jqx-docking-layout-overlay-highlight-bottom {
    bottom: 0;
    height: 9px;
    border-top-style: dashed;
}

.jqx-docking-layout-drop-overlay {
    display: none;
    background-color: #6495ED;
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    z-index: 10000;
    top: 0px;
    left: 0px;
}

.jqx-docking-layout-square-disabled {
    visibility: hidden;
}

.jqx-docking-layout-overlay-square-edge, .jqx-docking-layout-overlay-mini-window-edge-horizontal, .jqx-docking-layout-overlay-mini-window-edge-vertical {
    border-style: solid;
    border-width: 1px;
}

.jqx-docking-layout-overlay-inner-square-edge {
    width: 26px;
    height: 26px;
}

.jqx-docking-layout-overlay-mini-window-edge-horizontal {
    height: 24px;
}

.jqx-docking-layout-overlay-mini-window-edge-vertical {
    height: 13px;
}

.jqx-docking-layout-overlay-mini-window-edge-horizontal, .jqx-docking-layout-overlay-inner-square-header-horizontal, .jqx-docking-layout-overlay-inner-square-content-horizontal {
    width: 13px;
}

.jqx-docking-layout-overlay-inner-square-content-vertical {
    height: 7px;
}

.jqx-docking-layout-overlay-square-edge-arrow {
    border-style: solid;
    border-width: 5px;
    width: 0px;
    height: 0px;
    display: block;
    background: transparent !important;
}

.jqx-docking-layout-overlay-square-edge-arrow-left, .jqx-docking-layout-overlay-square-edge-arrow-right, .jqx-docking-layout-overlay-square-edge-arrow-top {
    border-top-color: transparent !important;
}

.jqx-docking-layout-overlay-square-edge-arrow-left, .jqx-docking-layout-overlay-square-edge-arrow-right, .jqx-docking-layout-overlay-square-edge-arrow-bottom {
    border-bottom-color: transparent !important;
}

.jqx-docking-layout-overlay-square-edge-arrow-left, .jqx-docking-layout-overlay-square-edge-arrow-top, .jqx-docking-layout-overlay-square-edge-arrow-bottom {
    border-left-color: transparent !important;
}

.jqx-docking-layout-overlay-square-edge-arrow-right, .jqx-docking-layout-overlay-square-edge-arrow-top, .jqx-docking-layout-overlay-square-edge-arrow-bottom {
    border-right-color: transparent !important;
}

.jqx-docking-layout-overlay-square-edge-arrow-left, .jqx-docking-layout-overlay-square-edge-arrow-right {
    margin-top: 8px;
}

.jqx-docking-layout-overlay-square-edge-arrow-top, .jqx-docking-layout-overlay-square-edge-arrow-bottom {
    margin-left: 8px;
}

.jqx-tag-cloud ul {
    padding-left: 5px;
}

.jqx-tag-cloud-item {
    padding: 3px;
    display: inline-block;
}

    .jqx-tag-cloud-item a {
        text-decoration: initial;
    }

        .jqx-tag-cloud-item a:hover {
            text-decoration: underline;
        }

.jqx-popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: #ffffff;
    border: 1px solid #aaa;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}

.jqx-popover-modal-background {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 999;
    /* Fallback for web browsers that don't support RGBa */
    background-color: rgb(0, 0, 0);
    /* RGBa with 0.6 opacity */
    background-color: rgba(0, 0, 0, 0.6);
    /* For IE 5.5 - 7*/
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000);
    /* For IE 8*/
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000)";
}

.jqx-popover-close-button {
    width: 16px;
    height: 16px;
    float: right;
}

.jqx-popover-close-button-rtl {
    float: left;
}

.jqx-popover.top {
    margin-top: -10px;
}

.jqx-popover.right {
    margin-left: 10px;
}

.jqx-popover.bottom {
    margin-top: 10px;
}

.jqx-popover.left {
    margin-left: -10px;
}

.jqx-popover-title {
    padding: 8px 14px;
    margin: 0;
    border-bottom: 1px solid inherit;
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
}

    .jqx-popover-title:empty {
        display: none;
    }

.jqx-popover-content {
    padding: 9px 14px;
}

.jqx-popover .jqx-popover-arrow,
.jqx-popover .jqx-popover-arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

.jqx-popover .jqx-popover-arrow {
    border-width: 11px;
}

    .jqx-popover .jqx-popover-arrow:after {
        border-width: 10px;
        content: "";
    }

.jqx-popover.top .jqx-popover-arrow {
    bottom: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-color: inherit;
    border-bottom-width: 0;
}

    .jqx-popover.top .jqx-popover-arrow:after {
        bottom: 1px;
        margin-left: -10px;
        border-top-color: #ffffff;
        border-bottom-width: 0;
    }

.jqx-popover.right .jqx-popover-arrow {
    top: 50%;
    left: -11px;
    margin-top: -11px;
    border-right-color: inherit;
    border-left-width: 0;
}

    .jqx-popover.right .jqx-popover-arrow:after {
        bottom: -10px;
        left: 1px;
        border-right-color: #ffffff;
        border-left-width: 0;
    }

.jqx-popover.bottom .jqx-popover-arrow {
    top: -11px;
    left: 50%;
    margin-left: -11px;
    border-bottom-color: inherit;
    border-top-width: 0;
}

    .jqx-popover.bottom .jqx-popover-arrow:after {
        top: 1px;
        margin-left: -10px;
        border-bottom-color: #ffffff;
        border-top-width: 0;
    }

.jqx-popover.left .jqx-popover-arrow {
    top: 50%;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: inherit;
}

    .jqx-popover.left .jqx-popover-arrow:after {
        right: 1px;
        bottom: -10px;
        border-left-color: #ffffff;
        border-right-width: 0;
    }


.jqx-kanban-full-frame {
    width: 100vw;
    height: 100vh;
}

.jqx-kanban-in-frame {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.jqx-kanban-rtl {
    direction: rtl;
    text-align: right;
}

.jqx-kanban-column {
    box-sizing: border-box;
    border-style: solid;
    border-width: 1px 1px 1px 0px;
    float: left;
}

.jqx-kanban-column-collapsed {
    width: 50px;
}

.jqx-kanban-column-first {
    border-width: 1px;
}

.jqx-kanban-column:first-of-type {
    border-width: 1px 1px 1px 1px;
}

.jqx-kanban-column-selected {
    /*  background-color: blue;*/
}

.jqx-kanban-column-header-collapsed { /*vertical column invisible*/
    display: none;
    box-sizing: border-box;
    height: 0px;
    width: 0px;
    position: relative;
    cursor: pointer;
}

.jqx-kanban-column-header-collapsed-show { /*vertical column visible*/
    display: block !important;
    height: 100%;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    cursor: pointer;
}

.jqx-kanban-column-vertical-container {
    padding-top: 0px;
    padding-left: 0px;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    white-space: nowrap;
}

.jqx-kanban-column-vertical-container-inverse {
    padding-top: 0px;
    padding-left: 20px;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    white-space: nowrap;
}

.jqx-kanban-column-vertical-container .jqx-kanban-column-header-title, .jqx-kanban-column-vertical-container .jqx-kanban-column-header-status {
    position: relative;
}

.jqx-kanban-column-hide { /*colapsed*/
    display: none !important;
}

.jqx-kanban-column-header {
    border-width: 1px;
    border-style: solid;
    margin: 1px;
    white-space: nowrap;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
}

.jqx-kanban-column-header-button {
    position: absolute;
    right: 0px;
    top: 50%;
    margin-top: -8px;
    width: 16px;
    height: 16px;
}

.jqx-kanban-column-header .jqx-window-collapse-button {
    margin-top: 1px !important;
}

.jqx-kanban-column-header-custom-button {
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -8px;
    width: 16px;
    height: 16px;
}

.jqx-kanban-column-header-custom-button-rtl {
    left: 20px;
}

.jqx-kanban-column-header-button-rtl {
    left: 0px;
}

.jqx-kanban-column-header-collapsed .jqx-kanban-column-header-button {
    top: 100%;
    right: 50%;
    margin-top: -21px;
    left: 50%;
    margin-left: -8px;
}

.jqx-kanban-column-header-collapsed .jqx-kanban-column-header-custom-button {
    top: 100%;
    right: 50%;
    margin-top: -41px;
    left: 50%;
    margin-left: -8px;
}

.jqx-kanban-column-container {
    margin: 1px;
    overflow-y: auto;
}

.jqx-kanban-item-placeholder {
    height: 100px;
    border: gray dotted 1px;
    border-radius: 3px;
    margin: 5px;
}

.jqx-kanban-column-vertical-container-inverse-ie8-fix {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
}

.jqx-kanban-column-vertical-container-ie8-fix {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.jqx-kanban-item {
    margin: 2px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: inherit;
    position: relative;
    box-shadow: #ddd 2px 2px 3px;
    cursor: pointer;
    touch-action: none;
    -ms-touch-action: none;
}

.jqx-kanban-item-selected {
}

.jqx-kanban-item-color-status {
    background-color: #6bbd49;
    width: 3px;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
}

.jqx-kanban-item-color-status-rtl {
    right: 0px;
    left: 100%;
}

.jqx-kanban-item-text {
    padding-left: 6px;
    padding-right: 23px;
    font-size: 14px;
    padding-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.jqx-kanban-rtl .jqx-kanban-item-text {
    padding-right: 6px;
    padding-left: 23px;
}

.jqx-kanban-column-header-title {
}

.jqx-kanban-column-header-status {
}

.jqx-kanban-item-content {
}

.jqx-kanban-item-avatar {
    width: 22px;
    height: 22px;
    position: absolute;
    right: 3px;
    top: 3px;
    background: #eee;
    text-align: center;
    overflow: hidden;
}

.jqx-kanban-item-avatar-rtl {
    left: 3px;
}

.jqx-kanban-item-avatar-image {
    width: 20px;
    height: 20px;
}

    .jqx-kanban-item-avatar-image:hover {
    }

.jqx-kanban-item-footer {
    border-top: 1px solid transparent;
    border-color: inherit;
    overflow: hidden;
    padding-top: 5px;
    margin-top: 5px;
    padding-bottom: 1px;
    padding-left: 6px;
    padding-right: 6px;
}


.jqx-kanban-item-keyword {
    float: left;
    margin: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid transparent;
    border-color: inherit;
    padding-left: 2px;
    padding-right: 2px;
    cursor: pointer;
}

.jqx-kanban-item-keyword-rtl {
    direction: rtl;
    text-align: right;
    float: right;
}

.jqx-kanban-item-keyword:hover {
}

.jqx-kanban-item-avatar {
    height: 20px;
}

.jqx-kanban-handle {
    cursor: move;
}

.jqx-kanban-clearing {
    clear: both;
}

.jqx-sortable {
    touch-action: none;
    -ms-touch-action: none;
}

.jqx-bar-gauge-tooltip {
    position: absolute;
    left: 20px;
    top: 20px;
    display: none;
    padding: 6px 12px;
    border: black solid 1px;
    background-color: white;
    color: #333333;
    font-size: 11px;
    font-weight: 100;
    font-family: Verdana;
    border: black solid 1px;
    border-radius: 3px;
    opacity: 1;
    white-space: nowrap;
    z-index: 5;
}

.jqx-bar-gauge-measure-text {
    position: absolute;
    top: 0;
    right: 0;
    visibility: hidden;
    z-index: -99999;
    margin: 0px;
    padding: 0px;
    border-width: 0px;
}

.jqx-scrollbar-mobile {
    background: transparent !important;
}

    .jqx-scrollbar-mobile .jqx-scrollbar-state-normal {
        background: transparent !important;
        border-color: transparent !important;
    }

    .jqx-scrollbar-mobile .jqx-scrollbar-thumb-state-normal-horizontal, .jqx-scrollbar-mobile .jqx-scrollbar-thumb-state-normal {
        background: #808080 !important;
        border-color: #808080 !important;
        border-width: 1px;
        z-index: 99999;
        border-radius: 0px !important;
    }

    .jqx-scrollbar-mobile .jqx-scrollbar-thumb-state-normal {
        width: 4px !important;
    }

    .jqx-scrollbar-mobile .jqx-scrollbar-thumb-state-normal-horizontal {
        height: 4px !important;
    }


.jqx-primary .jqx-icon-arrow-down, .jqx-warning .jqx-icon-arrow-down, .jqx-danger .jqx-icon-arrow-down, .jqx-success .jqx-icon-arrow-down, .jqx-info .jqx-icon-arrow-down {
    background-image: url('images/icon-down-white.png');
}

.jqx-primary .jqx-icon-arrow-down-selected, .jqx-warning .jqx-icon-arrow-down-selected, .jqx-danger .jqx-icon-arrow-down-selected, .jqx-success .jqx-icon-arrow-down-selected, .jqx-info .jqx-icon-arrow-down-selected {
    background-image: url('images/icon-down-white.png');
}

.jqx-primary .jqx-icon-arrow-down-hover, .jqx-warning .jqx-icon-arrow-down-hover, .jqx-danger .jqx-icon-arrow-down-hover, .jqx-success .jqx-icon-arrow-down-hover, .jqx-info .jqx-icon-arrow-down-hover {
    background-image: url('images/icon-down-white.png');
}

.jqx-primary .jqx-icon-arrow-up, .jqx-warning .jqx-icon-arrow-up, .jqx-danger .jqx-icon-arrow-up, .jqx-success .jqx-icon-arrow-up, .jqx-info .jqx-icon-arrow-up {
    background-image: url('images/icon-up-white.png');
}

.jqx-primary .jqx-icon-arrow-up-selected, .jqx-warning .jqx-icon-arrow-up-selected, .jqx-danger .jqx-icon-arrow-up-selected, .jqx-success .jqx-icon-arrow-up-selected, .jqx-info .jqx-icon-arrow-up-selected {
    background-image: url('images/icon-up-white.png');
}

.jqx-primary .jqx-icon-arrow-up-hover, .jqx-warning .jqx-icon-arrow-up-hover, .jqx-danger .jqx-icon-arrow-up-hover, .jqx-success .jqx-icon-arrow-up-hover, .jqx-info .jqx-icon-arrow-up-hover {
    background-image: url('images/icon-up-white.png');
}

.jqx-primary .jqx-icon-arrow-right, .jqx-warning .jqx-icon-arrow-right, .jqx-danger .jqx-icon-arrow-right, .jqx-success .jqx-icon-arrow-right, .jqx-info .jqx-icon-arrow-right {
    background-image: url('images/icon-right-white.png');
}

.jqx-primary .jqx-icon-arrow-right-selected, .jqx-warning .jqx-icon-arrow-right-selected, .jqx-danger .jqx-icon-arrow-right-selected, .jqx-success .jqx-icon-arrow-right-selected, .jqx-info .jqx-icon-arrow-right-selected {
    background-image: url('images/icon-right-white.png');
}

.jqx-primary .jqx-icon-arrow-right-hover, .jqx-warning .jqx-icon-arrow-right-hover, .jqx-danger .jqx-icon-arrow-right-hover, .jqx-success .jqx-icon-arrow-right-hover, .jqx-info .jqx-icon-arrow-right-hover {
    background-image: url('images/icon-right-white.png');
}

.jqx-primary .jqx-icon-arrow-left, .jqx-warning .jqx-icon-arrow-left, .jqx-danger .jqx-icon-arrow-left, .jqx-success .jqx-icon-arrow-left, .jqx-info .jqx-icon-arrow-left {
    background-image: url('images/icon-left-white.png');
}

.jqx-primary .jqx-icon-arrow-left-selected, .jqx-warning .jqx-icon-arrow-left-selected, .jqx-danger .jqx-icon-arrow-left-selected, .jqx-success .jqx-icon-arrow-left-selected, .jqx-info .jqx-icon-arrow-left-selected {
    background-image: url('images/icon-left-white.png');
}

.jqx-primary .jqx-icon-arrow-left-hover, .jqx-warning .jqx-icon-arrow-left-hover, .jqx-danger .jqx-icon-arrow-left-hover, .jqx-success .jqx-icon-arrow-left-hover, .jqx-info .jqx-icon-arrow-left-hover {
    background-image: url('images/icon-left-white.png');
}

.jqx-primary-item .jqx-listitem-state-hover, .jqx-primary-item .jqx-menu-item-hover, .jqx-primary-item .jqx-tree-item-hover, .jqx-primary-item .jqx-calendar-cell-hover, .jqx-primary-item .jqx-grid-cell-hover,
.jqx-primary-item .jqx-menu-vertical .jqx-menu-item-top-hover, .jqx-primary-item .jqx-input-popup .jqx-fill-state-hover,
.jqx-primary-item .jqx-input-popup .jqx-fill-state-pressed {
    background-color: #f5f5f5 !important;
    color: #333 !important;
    text-shadow: none !important;
    border-color: #f5f5f5 !important;
}

.jqx-primary-item .jqx-listitem-state-selected, .jqx-primary-item .jqx-menu-item-selected, .jqx-primary-item .jqx-tree-item-selected, .jqx-primary-item .jqx-calendar-cell-selected, .jqx-primary-item .jqx-grid-cell-selected,
.jqx-primary-item .jqx-menu-vertical .jqx-primary-item .jqx-menu-item-top-selected, .jqx-primary-item .jqx-grid-selectionarea, .jqx-primary-item .jqx-input-button-header, .jqx-primary-item .jqx-input-button-innerHeader {
    background-color: #f5f5f5 !important;
    color: #333 !important;
    text-shadow: none !important;
    border-color: #f5f5f5 !important;
}

.jqx-warning-item .jqx-listitem-state-hover, .jqx-warning-item .jqx-menu-item-hover, .jqx-warning-item .jqx-tree-item-hover, .jqx-warning-item .jqx-calendar-cell-hover, .jqx-warning-item .jqx-grid-cell-hover,
.jqx-warning-item .jqx-menu-vertical .jqx-menu-item-top-hover, .jqx-warning-item .jqx-input-popup .jqx-fill-state-hover,
.jqx-warning-item .jqx-input-popup .jqx-fill-state-pressed {
    background-color: #f5f5f5 !important;
    color: #333 !important;
    text-shadow: none !important;
    border-color: #f5f5f5 !important;
}

.jqx-warning-item .jqx-listitem-state-selected, .jqx-warning-item .jqx-menu-item-selected, .jqx-warning-item .jqx-tree-item-selected, .jqx-warning-item .jqx-calendar-cell-selected, .jqx-warning-item .jqx-grid-cell-selected,
.jqx-warning-item .jqx-menu-vertical .jqx-warning-item .jqx-menu-item-top-selected, .jqx-warning-item .jqx-grid-selectionarea, .jqx-warning-item .jqx-input-button-header, .jqx-warning-item .jqx-input-button-innerHeader {
    background-color: #f5f5f5 !important;
    color: #333 !important;
    text-shadow: none !important;
    border-color: #f5f5f5 !important;
}

.jqx-danger-item .jqx-listitem-state-hover, .jqx-danger-item .jqx-menu-item-hover, .jqx-danger-item .jqx-tree-item-hover, .jqx-danger-item .jqx-calendar-cell-hover, .jqx-danger-item .jqx-grid-cell-hover,
.jqx-danger-item .jqx-menu-vertical .jqx-menu-item-top-hover, .jqx-danger-item .jqx-input-popup .jqx-fill-state-hover,
.jqx-danger-item .jqx-input-popup .jqx-fill-state-pressed {
    background-color: #f5f5f5 !important;
    color: #333 !important;
    text-shadow: none !important;
    border-color: #f5f5f5 !important;
}

.jqx-danger-item .jqx-listitem-state-selected, .jqx-danger-item .jqx-menu-item-selected, .jqx-danger-item .jqx-tree-item-selected, .jqx-danger-item .jqx-calendar-cell-selected, .jqx-danger-item .jqx-grid-cell-selected,
.jqx-danger-item .jqx-menu-vertical .jqx-danger-item .jqx-menu-item-top-selected, .jqx-danger-item .jqx-grid-selectionarea, .jqx-danger-item .jqx-input-button-header, .jqx-danger-item .jqx-input-button-innerHeader {
    background-color: #f5f5f5 !important;
    color: #333 !important;
    text-shadow: none !important;
    border-color: #f5f5f5 !important;
}

.jqx-success-item .jqx-listitem-state-hover, .jqx-success-item .jqx-menu-item-hover, .jqx-success-item .jqx-tree-item-hover, .jqx-success-item .jqx-calendar-cell-hover, .jqx-success-item .jqx-grid-cell-hover,
.jqx-success-item .jqx-menu-vertical .jqx-menu-item-top-hover, .jqx-success-item .jqx-input-popup .jqx-fill-state-hover,
.jqx-success-item .jqx-input-popup .jqx-fill-state-pressed {
    background-color: #f5f5f5 !important;
    color: #333 !important;
    text-shadow: none !important;
    border-color: #f5f5f5 !important;
}

.jqx-success-item .jqx-listitem-state-selected, .jqx-success-item .jqx-menu-item-selected, .jqx-success-item .jqx-tree-item-selected, .jqx-success-item .jqx-calendar-cell-selected, .jqx-success-item .jqx-grid-cell-selected,
.jqx-success-item .jqx-menu-vertical .jqx-success-item .jqx-menu-item-top-selected, .jqx-success-item .jqx-grid-selectionarea, .jqx-success-item .jqx-input-button-header, .jqx-success-item .jqx-input-button-innerHeader {
    background-color: #f5f5f5 !important;
    color: #333 !important;
    text-shadow: none !important;
    border-color: #f5f5f5 !important;
}

.jqx-info-item .jqx-listitem-state-hover, .jqx-info-item .jqx-menu-item-hover, .jqx-info-item .jqx-tree-item-hover, .jqx-info-item .jqx-calendar-cell-hover, .jqx-info-item .jqx-grid-cell-hover,
.jqx-info-item .jqx-menu-vertical .jqx-menu-item-top-hover, .jqx-info-item .jqx-input-popup .jqx-fill-state-hover,
.jqx-info-item .jqx-input-popup .jqx-fill-state-pressed {
    background-color: #f5f5f5 !important;
    color: #333 !important;
    text-shadow: none !important;
    border-color: #f5f5f5 !important;
}

.jqx-info-item .jqx-listitem-state-selected, .jqx-info-item .jqx-menu-item-selected, .jqx-info-item .jqx-tree-item-selected, .jqx-info-item .jqx-calendar-cell-selected, .jqx-info-item .jqx-grid-cell-selected,
.jqx-info-item .jqx-menu-vertical .jqx-info-item .jqx-menu-item-top-selected, .jqx-info-item .jqx-grid-selectionarea, .jqx-info-item .jqx-input-button-header, .jqx-info-item .jqx-input-button-innerHeader {
    background-color: #f5f5f5 !important;
    color: #333 !important;
    text-shadow: none !important;
    border-color: #f5f5f5 !important;
}

.jqx-inverse-item .jqx-listitem-state-hover, .jqx-inverse-item .jqx-menu-item-hover, .jqx-inverse-item .jqx-tree-item-hover, .jqx-inverse-item .jqx-calendar-cell-hover, .jqx-inverse-item .jqx-grid-cell-hover,
.jqx-inverse-item .jqx-menu-vertical .jqx-menu-item-top-hover, .jqx-inverse-item .jqx-input-popup .jqx-fill-state-hover,
.jqx-inverse-item .jqx-input-popup .jqx-fill-state-pressed {
    background-color: #f5f5f5 !important;
    color: #333 !important;
    text-shadow: none !important;
    border-color: #f5f5f5 !important;
}

.jqx-inverse-item .jqx-listitem-state-selected, .jqx-inverse-item .jqx-menu-item-selected, .jqx-inverse-item .jqx-tree-item-selected, .jqx-inverse-item .jqx-calendar-cell-selected, .jqx-inverse-item .jqx-grid-cell-selected,
.jqx-inverse-item .jqx-menu-vertical .jqx-inverse-item .jqx-menu-item-top-selected, .jqx-inverse-item .jqx-grid-selectionarea, .jqx-inverse-item .jqx-input-button-header, .jqx-inverse-item .jqx-input-button-innerHeader {
    background-color: #f5f5f5 !important;
    color: #333 !important;
    text-shadow: none !important;
    border-color: #f5f5f5 !important;
}

.jqx-element {
    overflow: hidden;
    border-style: solid;
    border-width: 0px;
    border-color: transparent;
    box-sizing: border-box;
}

.jqx-element-no-border {
    border-width: 0px;
}

.jqx-element-container {
}

.jqx-resize-trigger, .jqx-resize-trigger-shrink {
    position: absolute;
    left: 0;
    top: 0;
    transition: 0s;
}

.jqx-resize-trigger-shrink {
    width: 200%;
    height: 200%;
}

.jqx-resize-trigger-container {
    display: block;
    visibility: hidden;
    position: relative;
    top: -100%;
    left: 0;
    min-height: 0.1px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: -1;
    visibility: hidden;
}

/* jqxPivotGrid */
.jqx-pivotgrid {
    background-color: #DEDEDE;
}

.jqx-pivotgrid-item {
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    padding: 0px;
    text-overflow: ellipsis;
}

.jqx-pivotgrid-content-wrapper {
    align: left;
    valign: top;
    overflow: hidden;
    border: solid 1px grey;
}

.jqx-pivotgrid-menu-button {
    border: none;
    background-image: url('images/icon-menu-small.png');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.jqx-pivotgrid-expand-button {
    outline: none;
    background-image: url('images/icon_expand.png');
    background-repeat: no-repeat;
    cursor: pointer;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    position: relative;
    left: 5px;
    float: left;
    vertical-align: middle;
}

.jqx-pivotgrid-collapse-button {
    outline: none;
    background-image: url('images/icon_collapse.png');
    background-repeat: no-repeat;
    cursor: pointer;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    position: relative;
    left: 5px;
    float: left;
    vertical-align: middle;
}

.jqx-pivotgrid-sortasc-icon {
    background-image: url('images/icon-sort-asc.png');
    background-repeat: no-repeat;
    background-position: left center;
    width: 16px;
    height: 16px;
    float: left;
    margin-left: -4px;
    margin-right: 4px;
}

.jqx-pivotgrid-sortdesc-icon {
    background-image: url('images/icon-sort-desc.png');
    background-repeat: no-repeat;
    background-position: left center;
    width: 16px;
    height: 16px;
    float: left;
    margin-left: -4px;
    margin-right: 4px;
}

.jqx-pivotgrid-sortremove-icon {
    background-image: url('images/icon-sort-remove.png');
    background-repeat: no-repeat;
    background-position: left center;
    width: 16px;
    height: 16px;
    float: left;
    margin-left: -4px;
    margin-right: 4px;
}

.jqx-pivotgrid-settings-icon {
    background-image: url('images/icon-menu-small.png');
    background-repeat: no-repeat;
    background-position: left center;
    width: 16px;
    height: 16px;
    float: left;
    margin-left: -4px;
    margin-right: 4px;
}

jqx-bullet-chart {
    width: 800px;
    height: 80px;
}

jqx-scroll-view {
    width: 600px;
}

jqx-bar-gauge, jqx-draw {
    width: 850px;
    height: 600px;
}

jqx-menu {
    width: 600px;
}

jqx-data-table, jqx-pivotgrid, jqx-grid, jqx-scheduler, jqx-tree-grid, jqx-docking-layout, jqx-layout, jqx-tree-map, jqx-splitter, jqx-kanban {
    width: 850px;
}

jqx-nav-bar {
    height: 50px;
}

jqx-chart {
    width: 850px;
    height: 400px;
}

jqx-docking-layout, jqx-layout {
    width: 850px;
    height: 600px;
}

jqx-ribbon, jqx-panel {
    width: 450px;
    height: 300px;
}

jqx-scheduler {
    height: 600px;
}

jqx-list-box, jqx-text-area {
    width: 200px;
    height: 200px;
}

jqx-file-upload {
    width: 300px;
}

jqx-complex-input, jqx-formatted-input, jqx-password-input, jqx-input, jqx-number-input, jqx-slider,
jqx-masked-input, jqx-drop-down-list, jqx-combo-box, jqx-date-time-input, jqx-drop-down-button {
    width: 300px;
    height: 35px;
}

jqx-loader {
    width: 100px !important;
    height: 60px !important;
}

jqx-expander, jqx-navigation-bar {
    width: 350px;
}

jqx-navigation-bar {
    height: 450px;
}

jqx-range-selector {
    width: 600px;
}

jqx-tabs {
    width: 800px;
}

jqx-tool-bar {
    width: 800px;
    height: 35px;
}

jqx-editor {
    width: 600px;
    height: 400px;
}

jqx-tree-map {
    width: 850px;
    height: 600px;
}

jqx-tree {
    width: 250px;
}

jqx-linear-gauge {
    width: 100px;
}

jqx-color-picker, jqx-calendar {
    width: 200px;
    height: 200px;
}

jqx-scroll-bar {
    width: 400px;
    height: 20px;
}

jqx-knob {
    width: 600px;
    height: 600px;
}

.jqx-input-label {
    visibility: hidden;
    position: absolute;
}

.jqx-input-bar {
    visibility: hidden;
    position: absolute;
}

.jqx-info-icon {
    background-image: url('images/icon-info-small.png');
    background-repeat: no-repeat;
    background-position: left center;
    width: 16px;
    height: 16px;
    float: left;
}

.jqx-labels-and-palette {
    display: flex;
}

.jqx-shades {
    width: 560px;
    padding-left: 100px;
}

.jqx-palette > ul,
.jqx-shades > ul,
.jqx-color-labels > ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.jqx-color-labels > ul {
    flex-direction: column;
}

.jqx-color-cell {
    width: 39px;
    height: 39px;
    margin: 0.5px;
    cursor: pointer;
}

.jqx-color-cell:hover {
    border-radius: 8px;
}

.jqx-shade-cell {
    width: 38px;
    height: 47px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0 1px 5px 1px;
    text-align: center;
}

.jqx-color-label {
    width: 100px;
    height: 40px;
    display: flex;
    align-items: center;
}

.jqx-heatmap-container-wrapper {
    display: flex;
 }

 .jqx-heatmap-container-wrapper canvas {
    display: block;
 }

 .jqx-heatmap-title {
    font-weight: bold;
    margin-bottom: 25px;
    text-align: center;
 }

 .jqx-heatmap-grid {
    display: flex;
    cursor: default;
 }

 .jqx-x-axis-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
 }

 .jqx-y-axis-container {
    display: flex;
    flex-direction: row-reverse;
    flex-grow: 1;
 }

 .jqx-column {
    display: flex;
    flex-direction: column-reverse;
 }

 .jqx-x-axis {
    display: flex;
 }

 .jqx-x-axis-label {
    padding-top: 10px;
    font-size: 14px;
    color: gray;
    text-align: center;
 }

 .jqx-x-axis-label.jqx-top {
    padding: 0 0 10px 0;
 }

 .jqx-y-axis {
    display: flex;
    flex-direction: column-reverse;
 }

 .jqx-y-axis.jqx-margin-top-auto {
    margin-top: auto;
 }

 .jqx-y-axis-label {
    display: flex;
    align-items: center;
    color: gray;
    padding-right: 10px;
 }

 .jqx-y-axis-label.jqx-right {
    padding: 0 0 0 10px;
 }

 .jqx-heatmap-cell {
    position: relative;
    padding: 20px 10px;
    margin: 1px;
    text-align: center;
 }

 .jqx-heatmap-cell.jqx-empty-cell {
    pointer-events: none;
 }

 .jqx-heatmap-tooltip {
    display: inline-block;
    position: absolute;
    background: #000;
    color: #fff;
    opacity: 0.6;
    padding: 10px;
    pointer-events: none;
    border-radius: 3px;
    transition: top 0.7s ease, left 0.7s ease;
    box-shadow: 0px 5px 3px -3px rgba(0, 0, 0, 0.75);
 }

 .jqx-heatmap-tooltip::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: calc(50% - 5px);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 5px 0 5px;
    border-color: #000 transparent transparent transparent;
 }

 .jqx-legend-vertical {
    position: relative;
    margin-left: 10px;
 }

 .jqx-legend-vertical.jqx-left {
    margin: 0 10px 0 0;
 }

 .jqx-legend-vertical.jqx-margin-top-auto {
    margin-top: auto;
 }

 .jqx-legend-horizontal {
    position: relative;
    margin-left: auto;
    margin-bottom: 40px;
 }

 .jqx-legend-horizontal.jqx-bottom {
    margin-top: 10px;
    margin-bottom: 30px;
 }

 .jqx-legend-horizontal.jqx-margin-right-auto {
    margin-right: auto;
    margin-left: 0;
 }

 .jqx-palette-vertical {
    width: 10px;
    height: 100%;
 }

 .jqx-palette-horizontal {
    width: 100%;
    height: 10px;
 }

 .jqx-palette-horizontal.jqx-fixed {
    display: flex;
 }

 .jqx-color-container,
 .jqx-color-container-tick {
    cursor: pointer;
 }

 .jqx-opacity-50 {
    opacity: 0.5;
 }

 .jqx-selected-color {
    background: #d3d3d3 !important;
 }

 .jqx-heatmap-hidden {
    visibility: hidden;
 }

 .jqx-tick-vertical {
    position: absolute;
    left: 15px;
 }

 .jqx-tick-vertical::before {
    content: '';
    width: 10px;
    background: #fff;
    height: 1px;
    position: absolute;
    top: 6px;
    left: -15px;
 }

 .jqx-tick-vertical.jqx-no-line::before,
 .jqx-tick-horizontal.jqx-no-line::before {
    content: none;
 }

 .jqx-tick-horizontal {
    position: absolute;
    top: 20px;
 }

 .jqx-tick-horizontal::before {
    content: '';
    width: 10px;
    background: #fff;
    width: 1px;
    height: 10px;
    position: absolute;
    top: -20px;
    left: 6px;
 }

 .jqx-arrow-vertical {
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid gray;
    position: absolute;
    left: -8px;
    display: none;
 }

 .jqx-arrow-horizontal {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid gray;
    position: absolute;
    top: 11px;
    left: -6px;
    display: none;
 }

 /*jqxTimePicker Style*/
.jqx-time-picker {
    display: block;
    border: 1px solid #c5c5c5;
    border-radius: 1px;
    font-family: Verdana;
    font-size: 12px;
    line-height: initial;
    touch-action: none;
    min-width: 250px;
    box-sizing: border-box;
}
.jqx-time-picker * {
    box-sizing: border-box;
}
.jqx-time-picker[view="landscape"] {
    min-width: 400px;
}
.jqx-time-picker>.jqx-container {
    width: 100%;
    height: 100%;
}
.jqx-time-picker[view="landscape"]>.jqx-container, .jqx-time-picker .jqx-header, .jqx-time-picker .jqx-svg-container, .jqx-time-picker .jqx-hour-minute-container {
    display: flex;
}
.jqx-time-picker .jqx-header {
    width: 100%;
    height: 20%;
    justify-content: center;
    align-items: center;
}
.jqx-time-picker[view="landscape"] .jqx-header {
    flex-direction: column;
    width: 33%;
    height: 100%;
}
.jqx-time-picker .jqx-main-container {
    width: 100%;
    height: 80%;
    padding: 2%;
    background-color: var(--jqx-time-picker-background);
}
.jqx-time-picker[view="portrait"] .jqx-header.jqx-widget-header.jqx-unselectable {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}
.jqx-time-picker[view="landscape"] .jqx-main-container {
    width: 67%;
    height: 100%;
}
.jqx-time-picker .jqx-svg-container {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.jqx-time-picker[footer] .jqx-svg-container {
    height: calc(100% - 30px);
}
.jqx-time-picker .jqx-footer {
    display: none;
}
.jqx-time-picker[footer] .jqx-footer {
    display: block;
    width: 100%;
    height: 30px;
}
.jqx-time-picker .jqx-hour-minute-container {
    font-size: 50px;
}
.jqx-time-picker .jqx-am-pm-container {
    margin-left: 10px;
}
.jqx-time-picker[view="landscape"] .jqx-am-pm-container {
    margin-left: 0;
}
.jqx-time-picker .jqx-hour-container, .jqx-time-picker .jqx-minute-container, .jqx-time-picker .jqx-am-container, .jqx-time-picker .jqx-pm-container {
    opacity: 0.5;
    cursor: pointer;
}
.jqx-time-picker[readonly] .jqx-hour-container, .jqx-time-picker[disabled] .jqx-hour-container, .jqx-time-picker[readonly] .jqx-minute-container, .jqx-time-picker[disabled] .jqx-minute-container, .jqx-time-picker[readonly] .jqx-am-container, .jqx-time-picker[disabled] .jqx-am-container, .jqx-time-picker[readonly] .jqx-pm-container, .jqx-time-picker[disabled] .jqx-pm-container {
    cursor: default;
}
.jqx-time-picker .jqx-selected {
    opacity: 1;
}
.jqx-time-picker .jqx-hour-minute-container div:nth-child(2) {
    opacity: 0.5;
}
.jqx-time-picker .jqx-svg-picker {
    border-radius: 50%;
    position: relative;
    box-sizing: content-box;
}
.jqx-time-picker .jqx-svg-picker.animate {
    animation: jqx-time-picker-animation 0.5s linear;
}
.jqx-time-picker[disabled] {
    opacity: 0.55;
    cursor: default;
    pointer-events: none;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.jqx-time-picker .jqx-label.jqx-selected {
    fill: white;
}
.jqx-time-picker .jqx-header:focus {
    outline: 1px solid skyblue;
}
.jqx-time-picker .jqx-svg-picker:focus {
    border: 1px solid skyblue;
    outline: none;
}
@keyframes jqx-time-picker-animation {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 0.5;
    }
    50% {
        opacity: 0;
    }
    75% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}
