/* You can add global styles to this file, and also import other style files */
@import "~angular-notifier/styles";
//@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
//@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
//html, body { height: 100%; }
//body {font-family: Roboto, "Helvetica Neue", sans-serif; }
//
///* Importing Bootstrap SCSS file. */
////@import '~bootstrap/scss/bootstrap';
////@import '~ngx-org-chart/_theming.scss';
//
//.fa[_ngcontent-hlf-c84] {
//  font-size: 20px !important;
//}
//.angular-editor-toolbar[_ngcontent-mwb-c84]{
//  font-size: 20px !important;
//}
